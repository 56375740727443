 const xby = {
		a1: "Introducción del proyecto",
		aa11:	"Crecimiento con el capital",
		aa12:	"Reglas del proyecto",
		aa13:	"Ventajas del proyecto",
		aa14:	"Seguridad del proyecto",
		aa15:	"Sostenibilidad del proyecto",
		aa16:	"Planificación futura",
		aa17:	"Plan de servicios premium",
		aa18:	"Directorio de ejecutivos",
		aa19:	"Socios",
		aa121:	"Directorio de inversores",
		aa122:	"Contacto",
		a2:"Introducción del proyecto",
		a3:"Antecedentes y establecimiento",
		a4:"La Fundación Future Horizons se estableció en 2021, con sede en Singapur. Es una organización sin fines de lucro dedicada a la investigación y aplicación de tecnología blockchain. La fundación se compromete a promover el desarrollo y la adopción de la tecnología blockchain, con el objetivo de crear un mundo digital más transparente, seguro y eficiente a través de la innovación tecnológica y la cooperación.",
		a5:"Introducción y distribución del token",
		a6:"Nombre del token",
		a7:"Cantidad inicial emitida",
		a8:"cien mil millones",
		a9:"Reglas de emisión adicional",
		a10:"Apueste 100 BNB para obtener 75 BNB en tokens.",
		a11:"Devuelve HFLH para recuperar el BNB apostado",
		a12:"Recuperar el BNB apostado y quemar todos los tokens correspondientes.",
		a13:"Uso del token",
		a14:"Cien millones de tokens y BNB inicial proporcionado por la fundación ingresan al pool de trading.",
		a15:"Objetivos del proyecto",
		a16:"La Fundación Future Horizons tiene como objetivo promover el desarrollo y la aplicación de la tecnología blockchain mediante la innovación tecnológica y la cooperación, fomentando el progreso y la prosperidad social. Los objetivos específicos incluyen innovación tecnológica, adopción generalizada, capacitación de talentos y responsabilidad social.",
		a17:"Objetivo de crear una fuente de riqueza",
		a18:"El objetivo central del proyecto HFLH es convertirse en una fuente de riqueza para todos los participantes. Esto significa que los participantes no solo pueden obtener beneficios iniciales, sino también obtener ingresos continuos a través de la participación a largo plazo. Al diseñar un ecosistema de criptomonedas estable y prometedor, HFLH tiene como objetivo crear una plataforma de riqueza sostenible y de valor creciente para todos los miembros.",
		a19:"Lógica de creación de valor de criptomonedas",
		a20:"La esencia de la criptomoneda radica en que representa valor. Cuando el precio de HFLH es de 1 BNB y el total es de cien millones, el valor de mercado total es de cien millones de BNB. Para los miembros que poseen HFLH, esto significa que su cantidad de tokens refleja directamente su riqueza. Por ejemplo, un miembro que posee 1000 HFLH puede canjearlos por 1000 BNB siempre que haya suficiente liquidez en el mercado. Por lo tanto, cuantos más miembros posean HFLH, más activo será el mercado y más estable será el valor de HFLH. En este contexto, el valor derivado del consenso se vuelve evidente. A través de la amplia participación del mercado y la disposición a mantener los tokens, HFLH puede lograr un valor estable y ser reconocido por el mercado. La formación de este consenso no solo depende del aumento en la cantidad de tokens mantenidos, sino también de la confianza continua del mercado en el valor de HFLH.",
		a21:"Dependencia de un consenso fuerte y sostenido",
		a22:"La clave del éxito de HFLH radica en establecer un consenso de mercado fuerte y sostenido. Sin embargo, no es suficiente confiar únicamente en conceptos. El mecanismo de préstamo con garantía proporciona un sólido respaldo de valor para HFLH. Cuando el precio de los tokens normales cae en el mercado, a menudo desencadena ventas de pánico, provocando una caída sin fondo en el precio del token, e incluso su colapso. A través del método de préstamo con garantía, los miembros pueden obtener nuevos tokens mientras aseguran la seguridad del capital. Específicamente, cuando un miembro apuesta 100 BNB, puede obtener tokens HFLH por valor de 75 BNB. Si el precio del token no aumenta más de un tercio, este miembro no venderá sus tokens fácilmente. Este mecanismo asegura institucionalmente que los nuevos participantes esperen pacientemente y no vendan fácilmente los tokens, incluso si el precio fluctúa. Al mismo tiempo, cuando el precio del token cae, los miembros pueden retirar su garantía de BNB sin vender HFLH en el mercado, evitando así la ocurrencia de eventos de liquidación.",
		a23:"Crecimiento continuo del valor de mercado y ganancias de los participantes",
		a24:"El valor de mercado de HFLH aumentará inevitablemente con el reconocimiento del mercado y el aumento de participantes. La fundación distribuirá el crecimiento del valor de mercado entre todos los participantes de diversas maneras, permitiéndoles compartir los beneficios del crecimiento de HFLH. Este mecanismo de reparto de beneficios no solo puede atraer a más participantes, sino también aumentar la confianza de los miembros existentes, promoviendo aún más la actividad del mercado. Al crear valor rápidamente y distribuirlo entre todos los participantes, HFLH puede formar un círculo virtuoso: el consenso de mercado se fortalece, el valor de mercado aumenta, más participantes se unen, lo que estabiliza y mejora aún más el valor de HFLH.",
		a25:"Crecimiento con el capital",
		a26:"Crecimiento de los participantes y del valor de mercado de HFLH",
		a27:"A medida que aumenta el número de participantes en HFLH, también lo hace su valor de mercado. Este crecimiento forma un ciclo positivo, ya que más participantes significan una mayor demanda de mercado, lo que impulsa el aumento del precio del token. El crecimiento del valor de mercado no se refiere solo al aumento del precio del token, sino también a la confianza y expectativas del mercado sobre el futuro del proyecto. La estabilidad en el crecimiento del valor de mercado es un factor importante para atraer grandes inversiones, ya que los inversores consideran tanto la estabilidad del mercado como el potencial de crecimiento al tomar decisiones de inversión.",
		a28:"Inversión de capital y estabilidad del mercado",
		a30:"La inversión de capital no solo trae más flujo de fondos, sino que también aumenta el reconocimiento y la influencia de HFLH en el mercado. Cuanto mayor sea la inversión de capital, mayor será la necesidad de estabilidad y crecimiento del valor de mercado de HFLH. El capital generalmente opta por invertir en proyectos con un valor de mercado estable y un potencial de crecimiento continuo para garantizar la seguridad y el potencial de apreciación de sus fondos. Para HFLH, siempre que pueda mantener un crecimiento estable del valor de mercado, continuará atrayendo la atención e inversión de grandes capitales.",
		a31:"Estrategia de retención de valor",
		a32:"El capital que participa en el proyecto HFLH no se apresura a obtener beneficios a corto plazo vendiendo tokens. Por el contrario, se centra más en el aumento del valor de mercado a largo plazo. La apreciación continua de los tokens HFLH significa que el valor de los tokens en poder del capital también está aumentando, lo que no solo puede mejorar el valor de mercado del capital, sino también aumentar el precio de sus acciones a través del reconocimiento del mercado. Por lo tanto, mantener los tokens HFLH y disfrutar de los beneficios a largo plazo derivados de su apreciación se convierte en la principal estrategia del capital.",
		a33:"Estabilidad del valor de mercado y confianza del capital",
		a34:"La clave del éxito del proyecto HFLH radica en mantener un crecimiento estable del valor de mercado. Esta estabilidad no solo proviene del aumento en la cantidad de participantes, sino también de la confianza del mercado en el futuro de HFLH. A través de la mejora continua del mecanismo del proyecto, aumentando la transparencia del mercado y la positividad de los participantes, HFLH puede establecer un fuerte sistema de confianza en el mercado. Siempre que HFLH pueda demostrar que tiene un potencial de crecimiento estable y sostenible, el capital estará dispuesto a mantener sus tokens a largo plazo, logrando así un crecimiento continuo del valor de mercado.",
		a35:"Diversidad de los participantes del mercado",
		a36:"A medida que el proyecto HFLH se desarrolla, los participantes del mercado no se limitarán a los inversores individuales, sino que también incluirán inversores institucionales y grandes capitales. Esta estructura diversificada de participantes puede proporcionar más apoyo de mercado y flujo de fondos para HFLH. La entrada de inversores institucionales y grandes capitales no solo puede aumentar la influencia del proyecto en el mercado, sino también ayudar a HFLH a lograr una expansión más rápida del mercado y un aumento del valor de mercado a través de sus estrategias y recursos de inversión profesional.",
		a37:"Resumen",
		a38:"El proyecto HFLH, a través del aumento continuo de participantes y el crecimiento constante del valor de mercado, atrae la inversión de grandes capitales y, mediante la estrategia de retención de valor, mejora el valor de mercado del capital en sí. Siempre que HFLH pueda mantener un crecimiento estable del valor de mercado, la confianza de los participantes del mercado aumentará continuamente, y el capital estará más dispuesto a mantener sus tokens a largo plazo, promoviendo el desarrollo continuo del proyecto y el aumento constante del valor de mercado.",
		a40:"A través de este mecanismo, HFLH no solo puede maximizar su valor de mercado, sino también proporcionar a los participantes y al capital una fuente estable de ingresos, formando un círculo virtuoso que fomenta el desarrollo sostenible a largo plazo del proyecto.",
		a50:"Reglas del proyecto",
		a51:"Préstamos con garantía",
		a52:"El proyecto HFLH permite a los miembros pedir prestados tokens con garantía de BNB según el precio en tiempo real. Las reglas específicas son las siguientes:",
		a53:"Los miembros pueden usar 100 BNB como garantía para pedir prestados tokens por valor de 75 BNB (por ejemplo).",
		a54:"Cuando los miembros devuelven los tokens prestados al contrato, pueden canjear completamente los 100 BNB en garantía.",
		a55:"Cada dirección puede tener hasta cinco préstamos activos al mismo tiempo.",
		a56:"Este mecanismo no solo proporciona liquidez, sino que también asegura que los miembros puedan obtener tokens mediante la garantía de BNB cuando sea necesario, permitiéndoles participar en más actividades del proyecto.",
		a57:"La cantidad inicial emitida es de cien millones de tokens. La única forma de aumentar la cantidad total de tokens posteriormente es a través del préstamo con garantía de BNB. Los tokens prestados aquí son tokens nuevos. Cuando los miembros devuelven los tokens y recuperan el BNB, los tokens se queman. Cada nuevo token emitido tiene un BNB correspondiente como garantía.",
		a60:"Staking y minería",
		a61:"El proyecto HFLH incentiva a los miembros a realizar staking de sus tokens para la minería. Este mecanismo se detiene cuando el valor total de mercado alcanza los veinte millones de BNB. Los siguientes parámetros se ajustarán según las condiciones del mercado, y los nuevos parámetros solo afectarán el nuevo staking; el staking existente no se verá afectado. Las reglas específicas son las siguientes:",
		a62:"Después de hacer staking de los tokens, los miembros pueden minar una vez al día.",
		a63:"La cantidad de tokens minados está directamente relacionada con la cantidad de tokens en staking.",
		a64:"Las opciones de tiempo de staking son tres: 10 días, 30 días y 90 días.",
		a65:"El rendimiento diario de la minería para el staking de 10 días es del 0.1%, pero la cantidad de tokens en staking no se cuenta para el cálculo de miembros efectivos y no genera ningún beneficio para los niveles superiores o los agentes.",
		a66:"El rendimiento diario de la minería para el staking de 30 días es del 0.2%. La cantidad de tokens en staking se puede utilizar para calcular miembros efectivos y genera beneficios para los niveles superiores.",
		a67:"El rendimiento diario de la minería para el staking de 90 días es del 0.3%. La cantidad de tokens en staking se puede utilizar para calcular miembros efectivos y genera beneficios para los niveles superiores.",
		a68:"Este mecanismo incentiva a los miembros a mantener tokens a largo plazo y también proporciona un suministro estable de tokens para el proyecto, promoviendo el desarrollo saludable del ecosistema.",
		a69:"Al realizar staking y minería, los miembros pueden proporcionar una dirección para el retorno del capital, es decir, cuando retiren el capital, se devolverá a la dirección especificada.",
		a70:"Ingresos del equipo",
		a71:"La fundación ofrece recompensas de recomendación a todos los miembros. Las reglas específicas del mecanismo de recompensas de recomendación son las siguientes:",
		a72:"Los miembros que realizan una vez staking y minería de 0.02BNB (según el precio en tiempo real en el momento del staking) y recomiendan a amigos para participar en staking y minería, pueden obtener ingresos de tres niveles.",
		a73:"Primer nivel 10%",
		a74:"Primer nivel 5%",
		a75:"Primer nivel 3%",
		a76:"Cuando los recomendados en los tres niveles realizan staking y minería, el recomendante puede obtener directamente beneficios en tokens.",
		a77:"Este mecanismo no solo alienta a los miembros a promover activamente el proyecto, sino que también aumenta la participación y la base de usuarios del proyecto.",
		a78:"Ingresos de agentes",
		a79:"La fundación ofrece un generoso mecanismo de recompensas para los agentes. Este sistema se detiene cuando el número de miembros alcanza los treinta millones. Las reglas específicas son las siguientes:",
		a80:"Los niveles de agentes se dividen en siete. Un nuevo miembro es un agente de nivel uno.",
		a81:"Miembro efectivo: Un staking y minería de una sola vez alcanza 0.2BNB, según el precio en tiempo real en el momento del staking. Se pueden hacer múltiples staking, siempre que uno de ellos alcance 0.2BNB, es un miembro efectivo.",
		a82:"Por cada número específico de miembros efectivos en tres niveles de subnivel, los agentes se actualizan hasta el nivel seis. Por ejemplo, cuando el número de miembros efectivos en tres niveles de subnivel alcanza 100, el nivel de agente es dos. Cuando el número de miembros efectivos en tres niveles de subnivel alcanza 300, el nivel de agente es tres.",
		a83:"Ingresos de agentes:",
		a84:"Agente de nivel uno (menos de 100 subniveles efectivos en tres niveles): Obtiene 0.02 BNB en tokens por cada miembro efectivo adicional.",
		a85:"Agente de nivel dos (menos de 300 subniveles efectivos en tres niveles): Obtiene 0.024BNB en tokens por cada miembro efectivo adicional (0.004 BNB más que el agente de nivel uno).",
		a86:"Agente de nivel tres (menos de 500 subniveles efectivos en tres niveles): Obtiene 0.028BNB en tokens por cada miembro efectivo adicional (0.004 BNB más que el agente de nivel dos).",
		a87:"Agente de nivel cuatro (menos de 700 subniveles efectivos en tres niveles): Obtiene 0.032BNB en tokens por cada miembro efectivo adicional (0.004 BNB más que el agente de nivel tres).",
		a88:"Agente de nivel cinco (menos de 1000 subniveles efectivos en tres niveles): Obtiene 0.036BNB en tokens por cada miembro efectivo adicional (0.004 BNB más que el agente de nivel cuatro).",
		a89:"Agente de nivel seis (menos de 1500 subniveles efectivos en tres niveles): Obtiene 0.04BNB en tokens por cada miembro efectivo adicional (0.004 BNB más que el agente de nivel cinco).",
		a90:"Método de distribución de ingresos:",
		a91:"Por cada miembro efectivo adicional, se otorga inmediatamente la mitad de los ingresos. Por ejemplo, cuando un agente de nivel uno agrega un miembro efectivo, se otorgan inmediatamente 0.01 BNB en tokens.",
		a92:"Cuando se agrega el miembro efectivo número cien, se otorgan todos los ingresos restantes de ese nivel al agente. Por ejemplo, cuando un agente de nivel uno agrega el miembro efectivo número cien, se otorgan 1.01BNB en tokens (0.01 BNB+ 100 *0.01 BNB), y el nivel del agente se actualiza a nivel dos.",
		a93:"Cuando un miembro alcanza el nivel seis y tiene 1500 miembros efectivos en tres niveles, se obtienen todos los ingresos del agente. No se otorgan ingresos adicionales al agregar el miembro efectivo número 1501.",
		a94:"Nota importante",
		a95:"Cuando un agente tiene 1500 miembros efectivos, se convertirá en accionista. Cada vez que se agregue un miembro efectivo en toda la red, el sistema emitirá tokens por valor de 0.02 BNB y los acumulará como ingresos para los accionistas.",
		a96:"Cuando el número de miembros efectivos alcance los treinta millones, todos los accionistas compartirán de una vez todos los ingresos acumulados.",
		a97:"Configuración de deslizamiento",
		a98:"Compra ",
		a99:"Venta ",
		a100:"Retiro ",
		b1:"Ventajas del proyecto",
		b2:" Ganancias sin riesgo",
		b3:"El proyecto HFLH obtiene tokens mediante préstamos con garantía. No hay riesgo de pérdida, ya sea manteniendo los tokens o participando en staking y minería. Ventajas específicas son las siguientes:",
		b4:"Seguridad del capital:",
		b5:"Si el precio del token cae, simplemente devuelva los tokens prestados al contrato y su capital está asegurado.",
		b6:"Ganancias sin riesgo:",
		b7:" Participar en staking y minería; incluso si el precio del token cae, las ganancias obtenidas de la minería son sin riesgo.",
		b8:" Beneficios incluso si el precio del token cae",
		b9:"El proyecto HFLH permite a los usuarios beneficiarse incluso si el precio del token cae. Ventajas específicas son las siguientes:",
		b10:"Mecanismo de venta en corto:",
		b11:" Los usuarios pueden vender los tokens prestados, y cuando el precio del token cae, recomprarlos con una parte de los fondos y devolverlos al contrato para recuperar el capital.",
		b12:"Beneficios bidireccionales:",
		b13:"Se puede obtener beneficio no solo cuando el precio del token sube, sino también cuando baja. Esto es una innovación importante en DeFi, diferenciándose de los proyectos tradicionales que solo pueden beneficiarse de la subida del precio.",
		b14:"Arquitectura distribuida",
		b15:"El proyecto HFLH adopta una arquitectura de red distribuida, con todos los datos y operaciones distribuidos en múltiples nodos globales. Ventajas específicas son las siguientes:",
		b16:"Sin puntos únicos de falla:",
		b17:" Incluso si algunos nodos fallan, el sistema puede seguir funcionando normalmente.",
		b18:"Alta disponibilidad:",
		b19:"El fallo de un solo nodo no afectará el funcionamiento del proyecto, asegurando la continuidad y estabilidad del proyecto.",
		b20:"Código abierto y transparencia",
		b21:"El código del proyecto HFLH es de código abierto, cualquiera puede revisarlo, copiarlo y usarlo. Ventajas específicas son las siguientes:",
		b22:"Transparencia:",
		b23:"Todas las reglas y operaciones son abiertas y transparentes, aumentando la confianza de los usuarios en el proyecto.",
		b24:"Mantenimiento comunitario:",
		b25:" Incluso si el equipo original abandona el proyecto, los miembros de la comunidad pueden continuar manteniendo y desarrollando el proyecto, asegurando su continuidad.",
		b26:"Restricciones del contrato inteligente",
		b27:"Las reglas operativas del proyecto HFLH están claramente definidas en el contrato inteligente. Todos los participantes, incluido el equipo del proyecto, solo pueden operar dentro de estas reglas. Ventajas específicas son las siguientes:",
		b28:"Reglas predeterminadas:",
		b29:" Cualquier operación fuera de las reglas se rechazará automáticamente, asegurando la justicia y transparencia del proyecto.",
		b30:"Restricciones de reglas:",
		b31:"El contrato inteligente en sí mismo es un sistema de reglas, cualquier operación debe cumplir con las condiciones predeterminadas del contrato, no se pueden violar las reglas especificadas previamente.",
		b32:"Seguridad del proyecto",
		b33:"Transparencia del contrato inteligente",
		b34:"El proyecto HFLH se basa en contratos inteligentes en la blockchain, que son completamente abiertos y transparentes. Cualquiera puede revisar y auditar el código del contrato para garantizar que se ejecute según las reglas predeterminadas. Esta transparencia reduce significativamente la posibilidad de comportamientos malintencionados y fraudes, aumentando la confianza de los usuarios en el proyecto.",
		b35:"Ejecución automática",
		b36:"Los contratos inteligentes del proyecto HFLH se ejecutan automáticamente al cumplir condiciones específicas, sin necesidad de intervención humana. Esta automatización no solo aumenta la eficiencia operativa, sino que también reduce el riesgo de errores humanos y fallos operativos, asegurando la precisión y consistencia en la ejecución del contrato.",
		b37:"Alta seguridad",
		b38:"El proyecto HFLH se basa en tecnología blockchain, utilizando su libro mayor distribuido y mecanismo de consenso para garantizar la seguridad y la inmutabilidad de los datos. La característica descentralizada de la blockchain hace que sea casi imposible manipular los registros de transacciones, asegurando la seguridad y fiabilidad del proyecto.",
		b39:"Sostenibilidad del proyecto",
		b40:"Arquitectura distribuida",
		b41:"El proyecto HFLH adopta una arquitectura de red distribuida, con todos los datos y operaciones distribuidos en múltiples nodos globales. Esto significa que incluso si algunos nodos fallan, todo el sistema puede seguir funcionando normalmente, sin puntos únicos de falla o cierres repentinos.",
		b42:"Renuncia total de control",
		b43:"Después del lanzamiento del proyecto HFLH, el equipo del proyecto renunciará completamente al control del proyecto. Una vez que los contratos inteligentes se despliegan en la blockchain, no pueden ser modificados o eliminados, asegurando que el equipo del proyecto no pueda cerrar o cambiar unilateralmente las reglas operativas del proyecto.",
		b44:"Inmutabilidad del contrato",
		b45:"Una vez que los contratos inteligentes del proyecto HFLH se despliegan en la blockchain, no pueden ser modificados. Esto significa que el equipo del proyecto no puede cerrar o cambiar las reglas operativas del proyecto a voluntad, deben seguir estrictamente el contrato, asegurando la transparencia y justicia del proyecto.",
		b46:"Código abierto",
		b47:"El código del proyecto HFLH es de código abierto, cualquiera puede revisarlo, copiarlo y usarlo. Esto limita aún más la capacidad de control del equipo del proyecto. Incluso si el equipo original abandona el proyecto, los miembros de la comunidad pueden continuar manteniéndolo y desarrollándolo, asegurando su continuidad.",
		b48:"Sin puntos únicos de falla",
		b49:"Debido a que el proyecto HFLH depende de una red distribuida, no hay riesgo de puntos únicos de falla. Incluso si un nodo falla, otros nodos pueden continuar operando y mantener la estabilidad de la red, asegurando que el proyecto no se cierre repentinamente.",
		b50:"Planificación futura",
		b51:"Plan de la Bolsa de Propiedad",
		b52:"El proyecto HFLH planea establecer una avanzada Bolsa de Propiedad en cooperación con Kirkland & Ellis LLP, una de las firmas de abogados más grandes del mundo, apoyándose en sus completos acuerdos legales. Esta bolsa permitirá la interconexión entre activos reales y digitales, haciendo que la negociación de diversos activos sea más rápida y segura.",
		b53:"Socios y seguridad legal",
		b54:"Kirkland & Ellis LLP es una de las principales firmas de abogados del mundo, con amplia experiencia en la gestión de asuntos legales internacionales y un equipo legal sólido. A través de la cooperación con Kirkland & Ellis LLP, HFLH asegurará la legalidad y efectividad de todas las transacciones y acuerdos legales. Esta cooperación proporcionará una sólida base legal para el funcionamiento de la Bolsa de Propiedad de HFLH, permitiendo la negociación de diversos activos de manera legal y segura a nivel mundial.",
		b55:"Mapeo y negociación de activos",
		b56:"En la Bolsa de Propiedad, los activos reales se mapearán digitalmente como activos digitales. Estos activos digitales incluyen, entre otros, diversas propiedades, automóviles, minerales, oro, granjas y empresas. A través de la tecnología blockchain, la propiedad y el historial de transacciones de estos activos se registrarán y almacenarán de forma segura, garantizando la transparencia e inmutabilidad de los activos.",
		b57:"Proceso de mapeo de activos digitales",
		b58:"Evaluación y certificación de activos",
		b59:"Primero, se lleva a cabo una evaluación y certificación profesional de los activos reales para garantizar la precisión y legitimidad de su valor.",
		b60:"Mapeo digital",
		b61:"Los activos evaluados se mapearán como activos digitales correspondientes, y se registrará su información de propiedad en la blockchain.",
		b62:"Publicación y negociación",
		b63:"Los activos digitales se publican en la Bolsa de Propiedad para que los usuarios los negocien.",
		b64:"Este método de mapeo de activos digitales no solo mejora la eficiencia de la negociación de activos, sino que también aumenta la transparencia y seguridad de las transacciones.",
		b65:"Negociación rápida y protección de la privacidad",
		b66:"Otra característica importante de la Bolsa de Propiedad es que permite una negociación rápida de diversos activos. Los usuarios pueden comprar, vender, transferir y pignorar activos en cualquier momento y lugar. En comparación con los métodos tradicionales de registro offshore y tenencia oculta, la Bolsa de Propiedad proporciona una forma más discreta de tenencia de bienes.",
		b67:"Proceso de negociación rápida",
		b68:"Listado de activos",
		b69:"Los usuarios pueden listar sus activos en la bolsa para su venta o alquiler.",
		b70:"Negociación en tiempo real",
		b71:"A través de un avanzado sistema de matching, los usuarios pueden realizar transacciones de activos en tiempo real, garantizando una finalización eficiente y rápida de las operaciones.",
		b72:"Liquidación de fondos",
		b73:"Después de completar una transacción, los fondos se liquidan automáticamente en la cuenta del usuario, asegurando la seguridad y prontitud de los fondos.",
		b74:"Mecanismos de protección de la privacidad",
		b75:"Cifrado de datos",
		b76:"Todos los datos de transacciones e información de los usuarios se cifrarán para evitar fugas de datos y accesos no autorizados.",
		b77:"Transacciones anónimas",
		b78:"Se admite la negociación anónima, los usuarios pueden optar por no revelar su identidad, aumentando la privacidad de las transacciones.",
		b79:"Protección de seguridad multicapa",
		b80:"A través de mecanismos de protección de seguridad de múltiples capas, se garantiza la seguridad de los activos e información de los usuarios.",
		b81:"Ventajas de la Bolsa de Propiedad",
		b82:"Plataforma de negociación global",
		b83:" La Bolsa de Propiedad será una plataforma de negociación global, atrayendo a usuarios y capital de todo el mundo. A través de tecnología avanzada y garantías legales completas, la Bolsa de Propiedad proporcionará a los usuarios un entorno de negociación de activos seguro, conveniente y eficiente.",
		b84:"Negociación de activos diversificada",
		b85:"Ya sea bienes raíces, automóviles, minerales, oro, granjas o empresas, la Bolsa de Propiedad puede realizar la negociación de una variedad de activos. Los usuarios pueden comprar y vender libremente diversos tipos de activos en la plataforma, lo que enriquece enormemente las opciones de negociación de activos.",
		b86:"Utilización eficiente de los fondos",
		b87:"A través de la Bolsa de Propiedad, los usuarios pueden lograr una utilización eficiente de sus activos. No solo pueden obtener ingresos mediante la venta y alquiler de activos, sino también obtener más apoyo financiero a través de la pignoración de activos y financiamiento. Esto proporciona a los usuarios más liquidez de fondos y mejora la eficiencia en el uso de los activos.",
		b88:"Perspectivas futuras",
		b89:"A medida que la Bolsa de Propiedad de HFLH se desarrolle y perfeccione, se convertirá en una plataforma de negociación de activos líder a nivel mundial. A través de la incorporación continua de tecnología avanzada y completas garantías legales, la Bolsa de Propiedad mejorará continuamente la experiencia del usuario y la eficiencia de la negociación, atrayendo a más usuarios y capital.",
		b90:"Innovación y desarrollo tecnológico",
		b91:"En el futuro, HFLH continuará explorando e introduciendo más tecnologías innovadoras, como la inteligencia artificial y el análisis de big data, para mejorar el nivel de inteligencia y automatización de la negociación. Estas tecnologías ayudarán a los usuarios a gestionar mejor sus activos y tomar decisiones de negociación, mejorando la eficiencia general de la negociación.",
		b92:"Expansión de mercado y cooperación",
		b93:"HFLH se expandirá activamente en los mercados globales, buscando más oportunidades de cooperación internacional. A través de la cooperación con instituciones financieras, instituciones legales y empresas tecnológicas de renombre mundial, HFLH mejorará aún más la influencia internacional y la competitividad en el mercado de la plataforma.",
		b94:"Conclusión",
		b95:"El establecimiento de la Bolsa de Propiedad de HFLH proporcionará a los usuarios una plataforma de negociación de activos segura, eficiente y discreta. A través de la cooperación con Kirkland & Ellis LLP, y apoyándose en completos acuerdos legales y tecnologías avanzadas, HFLH realizará la interconexión entre bienes reales y activos digitales, promoviendo el proceso de digitalización de la negociación de activos a nivel mundial. En el futuro, a medida que la plataforma continúe desarrollándose y perfeccionándose, HFLH se convertirá en un líder en el campo de la negociación de activos globales, ofreciendo a los usuarios y capitales servicios de mayor calidad y más oportunidades de inversión.",
		b96:"Plan de servicios premium",
		b97:" Descripción general de servicios premium",
		b98:"La fundación se compromete a proporcionar servicios personalizados completos y premium para los miembros. Estos servicios no solo abarcan todos los aspectos de la vida diaria, sino también algunas necesidades premium, asegurando que los miembros puedan disfrutar de la más alta calidad de vida. Al proporcionar estos servicios premium, HFLH no solo puede crear más valor agregado para los miembros, sino también generar un modelo de servicio de alto beneficio. Los servicios premium serán un paso clave en el aumento del precio de HFLH, ya que la mayor parte de las ganancias generadas se utilizarán para quemar HFLH, promoviendo la estabilidad y el crecimiento del valor del token HFLH.",
		b99:"Comida premium segura",
		b100:"En términos de alimentación, HFLH proporcionará a los miembros servicios de alimentos seguros, saludables y premium. Los detalles incluyen:",

		c1:"Alimentos orgánicos: Los miembros pueden disfrutar de alimentos orgánicos de las mejores granjas orgánicas del mundo, asegurando ingredientes naturales y saludables.",
		c2:"Menús de nutrición personalizados: Proporcionar menús de nutrición personalizados según la salud y preferencias dietéticas de los miembros, asegurando una ingesta nutricional equilibrada diaria.",
		c3:"Cocina internacional: Proporcionar comida de primera calidad de todo el mundo, satisfaciendo las preferencias de sabor de los miembros.",
		c4:"Chef privado: Proporcionar servicios de chef privado, el chef cocinará a domicilio, asegurando la frescura y calidad de los alimentos.",
		c5:" Compra y gestión de propiedades",
		c6:"HFLH proporcionará servicios completos de compra y gestión de propiedades para los miembros, asegurando la seguridad y el aumento de valor de sus activos. Los servicios específicos incluyen:",
		c7:" Bienes raíces globales: Los miembros pueden comprar propiedades premium en todo el mundo, incluyendo mansiones, apartamentos y resorts.",
		c8:"Evaluación profesional: Proporcionar servicios de evaluación profesional de bienes raíces, asegurando evaluaciones precisas del valor de las propiedades, ayudando a los miembros a tomar las mejores decisiones de inversión.",
		c9:"Préstamos hipotecarios: Proporcionar apoyo de préstamos hipotecarios, ayudando a los miembros a realizar la compra de la propiedad de sus sueños.",
		c10:"Gestión de propiedades: Proporcionar servicios completos de gestión de propiedades, incluyendo mantenimiento de propiedades, gestión de alquileres y estrategias de aumento de valor de los activos.",
		cc11:"Configuración y mantenimiento de vehículos",
		cc12:"Para satisfacer las necesidades de transporte premium de los miembros, HFLH proporcionará servicios completos de configuración y mantenimiento de vehículos. Los detalles incluyen:",
		cc13:"Configuración de automóviles de lujo: Proporcionar servicios de compra y personalización de automóviles de lujo, incluyendo las marcas más importantes del mundo.",
		cc14:"Servicios de yates: Proporcionar servicios de compra, personalización, atraque y mantenimiento de yates, asegurando que los miembros disfruten de una experiencia de lujo en el mar.",
		cc15:"Aviones privados: Proporcionar servicios de compra, arrendamiento y mantenimiento de aviones privados, asegurando la seguridad y comodidad de los viajes aéreos de los miembros.",
		c14:"Mantenimiento profesional: Proporcionar servicios de mantenimiento y reparación regulares para automóviles de lujo, yates y aviones privados, asegurando que todos los vehículos estén siempre en óptimas condiciones.",
		c15:" Servicios de mayordomo premium",
		c16:"HFLH proporcionará servicios de mayordomo premium para los miembros, asegurando una vida diaria ordenada. Los servicios específicos incluyen:",
		c17:"Mayordomo privado: Proporcionar servicios de mayordomo privado, el mayordomo se encargará de la gestión diaria de los miembros, incluyendo la programación, planificación de eventos y gestión de la vida.",
		c18:"Servicios domésticos: Proporcionar servicios completos de limpieza, lavandería y mantenimiento del hogar.",
		c19:"Asistente personal: Proporcionar servicios de asistente personal, el asistente ayudará a los miembros a manejar asuntos diarios y situaciones de emergencia, asegurando una vida sin preocupaciones.",
		c20:" Seguridad",
		c21:"HFLH valora altamente la seguridad de los miembros y proporcionará servicios de seguridad completos para asegurar la seguridad de la vida y los bienes de los miembros. Los detalles incluyen:",
		c22:"Guardaespaldas privados: Proporcionar servicios de guardaespaldas privados profesionales, los guardaespaldas tienen habilidades de seguridad de alto nivel y experiencia.",
		c23:"Monitoreo de seguridad: Proporcionar sistemas avanzados de monitoreo de seguridad, asegurando la seguridad del entorno residencial de los miembros.",
		c24:"Rescate de emergencia: Proporcionar servicios de rescate de emergencia las 24 horas, incluyendo primeros auxilios y manejo de emergencias.",
		c25:"Evaluación de riesgos: Proporcionar servicios profesionales de evaluación de riesgos de seguridad, ayudando a los miembros a identificar y evitar posibles riesgos de seguridad.",
		c26:"Consultoría financiera",
		c27:"HFLH proporcionará servicios profesionales de consultoría financiera para los miembros, ayudando a los miembros a planificar sus finanzas y gestionar sus activos de manera científica. Los detalles incluyen:",
		c28:"Asesoría de inversión: Proporcionar servicios profesionales de asesoría de inversión, ayudando a los miembros a formular estrategias de inversión personalizadas para lograr un crecimiento estable de los activos.",
		c29:"Consultoría fiscal: Proporcionar servicios de consultoría fiscal, ayudando a los miembros a planificar sus impuestos de manera razonable, minimizando la carga fiscal.",
		c30:"Planificación financiera: Proporcionar servicios completos de planificación financiera, incluyendo planificación de jubilación, planificación patrimonial y fondos de educación para los hijos.",
		c31:"Gestión de activos: Proporcionar servicios profesionales de gestión de activos, ayudando a los miembros a optimizar la configuración y aumentar el valor de los activos.",
		c32:" Educación para los hijos",
		c33:"HFLH valora altamente la educación de los hijos de los miembros y proporcionará servicios de apoyo educativo completos para asegurar el desarrollo integral de los hijos de los miembros. Los detalles incluyen:",
		c34:"Escuelas de élite: Ayudar a los hijos de los miembros a ingresar a las mejores escuelas del mundo, incluyendo escuelas internacionales y universidades de renombre.",
		c35:"Asesoría educativa: Proporcionar servicios profesionales de asesoría educativa, ayudando a los miembros a planificar la educación de sus hijos.",
		c36:"Tutoría académica: Proporcionar servicios de tutoría académica en todas las materias, ayudando a los hijos de los miembros a obtener excelentes resultados en todas las materias.",
		c37:"Actividades extracurriculares: Organizar actividades extracurriculares y clases de interés variadas, ayudando a los hijos de los miembros a desarrollarse integralmente.",
		c38:"Modelo de servicio de alta calidad y alta rentabilidad",
		c39:"Los servicios premium de HFLH se centrarán en alta calidad y alta rentabilidad, asegurando servicios de la más alta calidad y alto valor agregado. Las medidas específicas incluyen:",
		c40:"Selección estricta: Todos los proveedores de servicios serán seleccionados rigurosamente para asegurar la calidad y seguridad de los servicios.",
		c41:"Personalización: Proporcionar servicios personalizados según las necesidades individuales de los miembros, asegurando servicios precisos y atentos.",
		c42:"Alto valor agregado: Al proporcionar servicios de alto valor agregado, se logra una alta rentabilidad, asegurando la continuidad y estabilidad de los servicios.",
		c43:"Distribución de beneficios: La mayor parte de los beneficios obtenidos se utilizarán para quemar tokens HFLH, promoviendo aún más la estabilidad y el crecimiento del valor del token HFLH.",
		c44:" Perspectivas futuras",
		c45:"En el futuro, HFLH continuará mejorando la calidad y variedad de sus servicios premium, asegurando que los miembros puedan disfrutar de la mejor experiencia de servicio. Al mismo tiempo, HFLH expandirá activamente los mercados globales, atrayendo a más miembros premium para unirse. Al proporcionar servicios premium y un modelo de alta rentabilidad, se promoverá aún más la quema y el crecimiento del valor del token HFLH, logrando un desarrollo sostenible a largo plazo de HFLH.",
		c46:"Directorio de ejecutivos",
		c47:"Presidente",
		c48:" Cofundador de BlackRock, una de las compañías de gestión de activos más grandes del mundo, es una de las figuras más influyentes en las finanzas. Participa activamente en la mejora del medio ambiente y la comunidad, y sirve en varias juntas de organizaciones sin fines de lucro y universidades, incluidas la Universidad de Nueva York y el Hospital Presbiteriano de Nueva York.",
		c49:"Exdirectora de gestión en Google, con una comprensión profunda de la tecnología blockchain. Dirige la planificación y ejecución estratégica de HFLH, comprometida con la promoción del desarrollo global del proyecto.",
		c50:"Exdirector de tecnología blockchain en IBM, líder en innovación tecnológica. Tiene una amplia experiencia en el desarrollo e implementación de tecnología blockchain y es responsable de la arquitectura y desarrollo tecnológico de HFLH.",
		c51:"Exsocia senior en KPMG, con una amplia experiencia en gestión financiera. Es responsable de la planificación y gestión financiera de HFLH, asegurando la salud y transparencia financiera del proyecto.",
		c52:"Bufete de abogados de primera clase, proporcionando seguridad legal para HFLH.",
		c53:" Kirkland & Ellis LLP tiene una amplia experiencia en la gestión de asuntos legales internacionales y un sólido equipo legal, asegurando la legalidad y efectividad de todas las transacciones y acuerdos legales.",
		c54:"Banco de inversión de renombre mundial, proporcionando consultoría financiera y servicios de inversión para HFLH.",
		c55:"Morgan Stanley ofrece una amplia gama de servicios financieros en todo el mundo, ayudando a HFLH con operaciones de capital y expansión de mercado.",
		c56:"Proporciona soluciones de computación en la nube y almacenamiento de datos, apoyando la arquitectura tecnológica de HFLH.",
		c57:"Google Cloud proporciona servicios en la nube eficientes, seguros y confiables para HFLH, asegurando la alta disponibilidad y escalabilidad de la plataforma.",
		c58:"Proporciona soporte tecnológico blockchain, ayudando a HFLH a lograr innovación y desarrollo tecnológico.",
		c59:"IBM Blockchain tiene una amplia experiencia en el desarrollo e implementación de tecnología blockchain, apoyando la infraestructura blockchain de HFLH.",
		c60:"Directorio de inversores",
		c61:"Firma de capital de riesgo de primer nivel mundial, proporcionando apoyo de capital y orientación estratégica para HFLH. Sequoia Capital tiene una amplia experiencia en inversiones en tecnología e innovación, ayudando a HFLH a lograr un rápido crecimiento y expansión de mercado.",
		c62:"Firma de capital de riesgo de renombre, especializada en inversiones en tecnología y blockchain. Andreessen Horowitz ha proporcionado un apoyo financiero importante y orientación técnica para HFLH, impulsando la innovación tecnológica y la expansión del mercado del proyecto.",
		c63:"Ha invertido en varias compañías de tecnología reconocidas, proporcionando apoyo de crecimiento para HFLH. Union Square Ventures tiene una amplia experiencia en el impulso de empresas innovadoras, ayudando a HFLH a ganar una posición favorable en la competencia del mercado.",
		c64:"Firma de capital de riesgo de influencia global, apoyando el desarrollo internacional de HFLH. Lightspeed Venture Partners, a través de su red y recursos globales, ayuda a HFLH a expandirse en el mercado global y aumentar sus negocios.",
		c65:"Contacto",
		c66:"Correo electrónico",
		c67:"Twitter",
		c68:"Descargar el whitepaper",
		c69:"Haga clic para descargar el whitepaper"

	

}
	export default xby
<template>
	<div id="app" class=" " @click.stop="zhegsg">
		<div class="header">
			<div class="head_boder">
				<!-- <div id="targetLocation">dffdjjfdjdjd</div> -->
				<!-- pc头部 ishow-->
				<div class="contercom  " v-if="ispc">
					<img class="login" src="./assets/5.png" alt="">
					<a href="#a1">
						<div class="deli">
							<!-- 项目介绍 -->
							{{$t('aa11')}}
						</div>
					</a>
					<a href="#a2">
						<div class="deli">
							<!-- 与资本的共同成长 -->
							{{$t('aa12')}}
						</div>
					</a>
					<a href="#a3">
						<div class="deli">
							<!-- 项目制度 -->
							{{$t('aa13')}}
						</div>
					</a>
					<a href="#a4">
						<div class="deli">
							<!-- 项目优势 -->
							{{$t('a1')}}
						</div>
					</a>
					<a href="#aqx">
						<div class="deli">
							<!-- 项目安全性 -->
							{{$t('aa14')}}
						</div>
					</a>

					<a href="#a5">
						<div class="deli">
							{{$t('aa15')}}
						</div>
					</a>
					<a href="#a6">
						<div class="deli">
							{{$t('aa16')}}
						</div>
					</a>
					<a href="#a7">
						<div class="deli">
							{{$t('aa17')}}
						</div>
					</a>
					<a href="#a8">
						<div class="deli">
							{{$t('aa18')}}
						</div>
					</a>
					<a href="#a9">
						<div class="deli">
							{{$t('aa19')}}
						</div>
					</a>
					<a href="#a10">
						<div class="deli">
							{{$t('aa121')}}
						</div>
					</a>
					<a href="#a11">
						<div class="deli">
							{{$t('aa122')}}
						</div>
					</a>
					
					
						<div class="deli huygd"   @click.stop="gosele" v-if="ispc ==true  ">
							{{yuname}}
							
							<div class="head_may" v-if="ishidepc  ">
								<div class="ydlia">
									<div class="sange">
										
										<div class="jya" >
											<div class="vhs " :class=" double ==index?'borez':'' "
												v-for="(item,index) in yuliat" :key="index" @click="langclick(index)">
												{{item}}
											</div>
										</div>
							
									</div>
									<img @click="gosan" class="bired" src="./assets/san.png" alt="" />
								</div>
							</div>
							
							
						</div>
					





					<img class="bired" src="./assets/san.png" alt="" />
				</div>
				<!-- 移动端头部  ishide-->
				<div class="" v-if="ispc ==false">
					<div class="contercom  " style="background-color: #000;">

						<div class="head_may">
							<img class="login" src="./assets/5.png" alt="">
							<div class="ydlia">
								<div class="sange" style="	position: relative;
								">
									<div class="yuy" @click.stop="changeclsoe">
										{{yuname}}
										<img src="./assets/xjt.png" alt="" />

									</div>
									<div class="jya" style="position: absolute;right: 0;" v-if=" isayu==true ">
										<div class="vhs " :class=" double ==index?'borez':'' "
											v-for="(item,index) in yuliat" :key="index" @click="langclick(index)">
											{{item}}
										</div>
									</div>

								</div>
								<img @click="gosan" class="bired" src="./assets/san.png" alt="" />
							</div>
						</div>
					</div>
					<div class="maishead " v-if="isihdehead">
						<div class="deli2">
							<!-- <div class="text">{{$t('aa11')}}</div> -->
							<a href="#a1">
								<div class="text">
									<!-- 项目介绍 -->
									{{$t('aa11')}}
								</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>

						<div class="deli2">
							<a href="#a2">
								<div class="text">{{$t('aa12')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>

						<div class="deli2">
							<a href="#a3">
								<div class="text">{{$t('aa13')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>

						<div class="deli2">
							<a href="#a4">
								<div class="text">{{$t('a1')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>

						<div class="deli2">
							<a href="#aqx">
								<div class="text">{{$t('aa14')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a5">
								<div class="text">{{$t('aa15')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a6">
								<div class="text">{{$t('aa16')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a7">
								<div class="text">{{$t('aa17')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a8">
								<div class="text">{{$t('aa18')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a9">
								<div class="text">{{$t('aa19')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a10">
								<div class="text">{{$t('aa121')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
						<div class="deli2">
							<a href="#a11">
								<div class="text">{{$t('aa122')}}</div>
							</a>
							<img class="hts" src="./assets/jt.png" alt="" />
						</div>
					</div>

				</div>
				<!-- 内容 -->

				<div class="coinmer">
					<!-- 页面一项目介绍 -->
					<div class="one" id="a1">
						<div class="onetiele">
							<div class="orser">{{$t('aa11')}}</div>
							<div class="bore"></div>
							<div class="pro">PROJECT INTRODUCTION </div>
						</div>
						<div class="clist">
							<div class="bjh">
								<div class="one_left">
									<div class="xmbg">{{$t('a3')}}</div>
								</div>
								<div class="one_right">
									<div class="cotejs">
										{{$t('a4')}}
									</div>
									<img class="blo" src="./assets/v.png" alt="" />
									<img class="xjtd" src="./assets/3.png" alt="" />
								</div>
							</div>

							<div class="bjh">
								<div class="one_left">
									<div class="xmbg">{{$t('a5')}}</div>
								</div>
								<div class="one_right">
									<div class="thres">
										<div class="binae">
											<img class="a1" src="./assets/1.png" alt="" />
											<div class="cfd">
												<div class="nmas">{{$t('a6')}}HFLH</div>
												<div class="xjd"></div>
											</div>
										</div>
										<div class="binae">
											<img class="a1" src="./assets/e.png" alt="" />
											<div class="cfd">
												<div class="nmas">{{$t('a7')}}{{$t('a8')}}</div>
												<div class="xjd"></div>
											</div>
										</div>
										<div class="binae">
											<img class="a1" src="./assets/f.png" alt="" />
											<div class="cfd">
												<!-- {{$t('a9')}} -->
												<div class="nmas"></div>
												<div class="xjd">{{$t('a10')}}</div>
											</div>
										</div>
										<div class="binae">
											<img class="a1" src="./assets/123.png" alt="" />
											<div class="cfd">
												<div class="nmas">{{$t('a11')}}</div>
												<!-- <div class="xjd"> {{$t('a12')}}</div> -->
											</div>
										</div>
								<!-- 		<div class="binae">
											<img class="a1" src="./assets/t.png" alt="" />

											<div class="cfd">
												<div class="nmas">{{$t('a13')}}</div>
												<div class="xjd">{{$t('a14')}}</div>
											</div>
										</div> -->
									</div>
								</div>
							</div>
							<!-- <a href="#targetLocation">跳转到目标位置</a > -->
							<div class="bjh">
								<div class="one_left">
									<div class="xmbg">{{$t('a15')}}</div>
								</div>
								<div class="one_right">
									<div class="cotejs">
										{{$t('a16')}}
									</div>
									<img class="blo" src="./assets/q.png" alt="" />
									<img class="xjtd" src="./assets/3.png" alt="" />
								</div>
							</div>

							<div class="bjh">
								<div class="one_left">
									<div class="xmbg"> {{$t('a17')}}</div>
								</div>
								<div class="one_right">
									<div class="cotejs">
										{{$t('a18')}}
									</div>
									<img class="blo" src="./assets/w.png" alt="" />
									<img class="xjtd" src="./assets/3.png" alt="" />
								</div>
							</div>

							<div class="bjh">
								<div class="one_left">
									<div class="xmbg"> {{$t('a19')}}</div>
								</div>
								<div class="one_right">
									<div class="cotejs">
										{{$t('a20')}}
									</div>
									<img class="blo" src="./assets/s.png" alt="" />
									<img class="xjtd" src="./assets/3.png" alt="" />
								</div>
							</div>


							<div class="bjh" style="padding: 20px 0 60px;!important">
								<div class="one_left">
									<div class="xmbg"> {{$t('a21')}}</div>
								</div>
								<div class="one_right">
									<div class="cotejs">
										{{$t('a22')}}
									</div>
									<img class="blo" src="./assets/a.png" alt="" />
									<img class="xjtd" src="./assets/3.png" alt="" />
								</div>
							</div>


							<div class="bjh" style="border:  none !important;">
								<div class="one_left">
									<div class="xmbg"> {{$t('a23')}}</div>
								</div>
								<div class="one_right">
									<div class="cotejs">
										{{$t('a24')}}
									</div>
									<img class="blo" src="./assets/vf.png" alt="" />
									<img class="xjtd" src="./assets/3.png" alt="" />
								</div>
							</div>

						</div>
					</div>

					<div class="two">
						<!-- 与资本共同成长 -->
						<div class="onetiele" id="a2">
							<div class="orser">{{$t('a25')}}</div>
							<div class="bore"></div>
							<div class="pro">CO GROWTH WITH CAPITAL </div>
						</div>
						<div class="coninlist">
							<div class="two_kai">
								<img src="./assets/jsd.png" alt="">
							</div>
							<div class="two_lis" style="align-items: flex-end;">
								<div class="halia">
									<div class="tilna">
										{{$t('a26')}}
									</div>
									<div class="acout">
										{{$t('a27')}}
									</div>
								</div>
							</div>

						</div>

						<div class="conlw">
							<div class="two_lis">
								<div class="halia">
									<div class="tilna">
										{{$t('a28')}}
									</div>
									<div class="acout">
										{{$t('a30')}}
									</div>
								</div>
							</div>
							<div class="two_lis">
								<div class="halia">
									<div class="tilna">
										{{$t('a31')}}
									</div>
									<div class="acout">
										{{$t('a32')}}
									</div>
								</div>
							</div>
							<div class="two_lis">
								<div class="halia">
									<div class="tilna">
										{{$t('a33')}}
									</div>
									<div class="acout">
										{{$t('a34')}}
									</div>
								</div>
							</div>
							<div class="two_lis">
								<div class="halia">
									<div class="tilna">
										{{$t('a35')}}
									</div>
									<div class="acout">
										{{$t('a36')}}
									</div>
								</div>
							</div>
						</div>
						<div class="aljs">
							<div class="lizj">{{$t('a37')}}</div>
							<div class="rith">
								<div class="topsj">
									{{$t('a38')}}
								</div>
								<div class="topsj" style="margin-top: 20px;">
									{{$t('a40')}}
								</div>
							</div>
						</div>
					</div>


					<!-- 项目制度 -->
					<div class="threes">
						<!-- 项目制度 -->
						<div class="onetiele" id="a3">
							<div class="orser"> {{$t('a50')}}</div>
							<div class="bore"></div>
							<div class="pro">PROJECT SYSTEM </div>
						</div>

						<div class="syscont">
							<div class="achyiya_toptre">
								<div class="jdai">
									<div class="zys">
										{{$t('a51')}}
									</div>
									<div class="tiod">
										{{$t('a52')}}
									</div>
									<div class="alis">
										<ul>
											<li> {{$t('a53')}}</li>
											<li> {{$t('a54')}}</li>
											<li> {{$t('a55')}}</li>
											<li> {{$t('a56')}}</li>
											<li> {{$t('a57')}}</li>
										</ul>
									</div>
								</div>

								<div class="jdai">
									<div class="zys">
										{{$t('a60')}}
									</div>
									<div class="tiod">
										{{$t('a61')}}
									</div>
									<div class="alis">
										<ul>
											<li> {{$t('a62')}}</li>
											<li> {{$t('a63')}}</li>
											<li> {{$t('a64')}}
												<div class="has">
													<ul>
														<li> {{$t('a65')}}</li>
														<li> {{$t('a66')}}</li>
														<li> {{$t('a67')}}</li>
													</ul>
												</div>
											</li>
											<li> {{$t('a68')}}</li>
											<li> {{$t('a69')}}</li>
										</ul>
									</div>
								</div>

								<div class="jdai">
									<div class="zys">
										{{$t('a70')}}
									</div>
									<div class="tiod">
										{{$t('a71')}}
									</div>
									<div class="alis">
										<ul>
											<li> {{$t('a72')}}
												<div class="has ">
													<ul class="hays">
														<li> {{$t('a73')}}</li>
														<li> {{$t('a74')}}</li>
														<li> {{$t('a75')}}</li>
													</ul>
												</div>
											</li>
											<li> {{$t('a76')}}</li>
											<li> {{$t('a77')}}</li>
										</ul>
									</div>
								</div>
							</div>

							<div class="boed">
								<div class="jdai xiah" style="margin: 20px 0; padding: 20px 20px;!important">
									<div class="zys">
										{{$t('a78')}}
									</div>
									<div class="tiod">
										{{$t('a79')}}
									</div>
									<div class="alis">
										<ul>
											<li> {{$t('a80')}}</li>
											<li> {{$t('a81')}}
											</li>
											<li> {{$t('a82')}}</li>
											<li> {{$t('a83')}}
												<div class="has">
													<ul>
														<li> {{$t('a84')}}</li>
														<li> {{$t('a85')}}</li>
														<li> {{$t('a86')}}</li>
														<li> {{$t('a87')}}</li>
														<li> {{$t('a88')}}</li>
														<li> {{$t('a89')}}
														</li>
													</ul>
												</div>
											</li>
											<li> {{$t('a90')}}
												<div class="has">
													<ul>
														<li> {{$t('a91')}}</li>
														<li> {{$t('a92')}}</li>
														<li> {{$t('a93')}}</li>
													</ul>
												</div>
											</li>
											<div class="tiod"> {{$t('a94')}}</div>
											<li> {{$t('a95')}}</li>
											<li> {{$t('a96')}}</li>
										</ul>
									</div>
								</div>

								<div class="jdai " :class=" ispc?'bgs':'bgs2' "
									style="margin: 20px 0; padding: 20px 20px;!important">
									<div class="zys">
										{{$t('a97')}}
									</div>
									<div class="circles">
										<div class="circle">
											<div class="mr">
												3
											</div>
											<div class="hans">
												{{$t('a98')}}
											</div>
										</div>
										<div class="circle">
											<div class="mr">
												3
											</div>
											<div class="hans">
												{{$t('a99')}}
											</div>
										</div>
										<div class="circle">
											<div class="mr">
												1
											</div>
											<div class="hans">
												{{$t('a100')}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- 项目优势 -->

					<div class="goodpare" id="a4">

						<div class="onetiele">
							<div class="orser"> {{$t('b1')}}</div>
							<div class="bore"></div>
							<div class="pro">PROJECT ROJECT ADVANTAGES </div>
						</div>

						<div class="gouyx">
							<div class="goue">
								<div class="content">
									<div class="left">01</div>
									<div class="line"></div>
									<div class="right">{{$t('b2')}}</div>
								</div>
								<div class="coter">
									{{$t('b3')}}
								</div>
								<div class="achdo">
									<div class="suodk">
										<div class="tha">
											{{$t('b4')}}
										</div>
										<div class="neid">
											{{$t('b5')}}
										</div>
									</div>
									<div class="suodk">
										<div class="tha">
											{{$t('b6')}}
										</div>
										<div class="neid">
											{{$t('b7')}}
										</div>
									</div>
								</div>
							</div>
							<div class="goue">
								<div class="content">
									<div class="left">02</div>
									<div class="line"></div>
									<div class="right">{{$t('b8')}}</div>
								</div>
								<div class="coter">
									{{$t('b9')}}
								</div>
								<div class="achdo">
									<div class="suodk">
										<div class="tha">
											{{$t('b10')}}
										</div>
										<div class="neid">
											{{$t('b11')}}
										</div>
									</div>
									<div class="suodk">
										<div class="tha">
											{{$t('b12')}}
										</div>
										<div class="neid">
											{{$t('b13')}}
										</div>
									</div>
								</div>
							</div>

							<div class="goue">
								<div class="content">
									<div class="left">03</div>
									<div class="line"></div>
									<div class="right">{{$t('b14')}}</div>
								</div>
								<div class="coter">
									{{$t('b15')}}
								</div>
								<div class="achdo">
									<div class="suodk">
										<div class="tha">
											{{$t('b16')}}
										</div>
										<div class="neid">
											{{$t('b17')}}
										</div>
									</div>
									<div class="suodk">
										<div class="tha">
											{{$t('b18')}}
										</div>
										<div class="neid">
											{{$t('b19')}}
										</div>
									</div>
								</div>
							</div>

							<div class="goue">
								<div class="content">
									<div class="left">04</div>
									<div class="line"></div>
									<div class="right">{{$t('b20')}}</div>
								</div>
								<div class="coter">
									{{$t('b21')}}
								</div>
								<div class="achdo">
									<div class="suodk">
										<div class="tha">
											{{$t('b22')}}
										</div>
										<div class="neid">
											{{$t('b23')}}
										</div>
									</div>
									<div class="suodk">
										<div class="tha">
											{{$t('b24')}}
										</div>
										<div class="neid">
											{{$t('b25')}}
										</div>
									</div>
								</div>
							</div>

							<div class="goue">
								<div class="content">
									<div class="left">05</div>
									<div class="line"></div>
									<div class="right">{{$t('b26')}}</div>
								</div>
								<div class="coter">
									{{$t('b27')}}
								</div>
								<div class="achdo">
									<div class="suodk">
										<div class="tha">
											{{$t('b28')}}
										</div>
										<div class="neid">
											{{$t('b29')}}
										</div>
									</div>
									<div class="suodk">
										<div class="tha">
											{{$t('b30')}}
										</div>
										<div class="neid">
											{{$t('b31')}}
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="goue" v-if="!ispc " ></div> -->

						</div>
					</div>
				</div>

				<!-- 项目安全性 -->
				<div class="secure" id="aqx">
					<div class="coinmer">
						<div class="onetiele">
							<div class="orser">{{$t('b32')}}</div>
							<div class="bore"></div>
							<div class="pro">PROJECT SECURITY </div>
						</div>

						<div class="theewd">
							<div class="heyus">
								<img src="./assets/001.png" alt="" />
								<div class="lais">
									<div class="hysd">{{$t('b33')}}</div>
									<div class="hex"></div>
								</div>
								<div class="jisoev">
									{{$t('b34')}}
								</div>
							</div>
							<div class="heyus  ldkf">
								<img src="./assets/002.png" alt="" />
								<div class="lais">
									<div class="hysd">{{$t('b35')}}</div>
									<div class="hex"></div>
								</div>
								<div class="jisoev">
									{{$t('b36')}}
								</div>
							</div>
							<div class="heyus">
								<img src="./assets/003.png" alt="" />
								<div class="lais">
									<div class="hysd"> {{$t('b37')}}</div>
									<div class="hex"></div>
								</div>
								<div class="jisoev">
									{{$t('b38')}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 项目持续性 -->
				<div class="coallcy" id="a5">
					<div class="coinmer">
						<div class="cxj">
							<div class="onetiele">
								<div class="orser">{{$t('b39')}}</div>
								<div class="bore"></div>
								<div class="pro">PROJECT CONTINUITY </div>
							</div>

							<div class="coyd">
								<div class="yitd">
									<img src="./assets/6.png" alt="" />
									<div class="lidd">
										<div class="tilf">
											{{$t('b40')}}
										</div>
										<div class="hex"></div>
									</div>
									<div class="coye">
										{{$t('b41')}}
									</div>
								</div>
								<div class="yitd">
									<img src="./assets/6.png" alt="" />
									<div class="lidd">
										<div class="tilf">
											{{$t('b42')}}
										</div>
										<div class="hex"></div>
									</div>
									<div class="coye">
										{{$t('b3')}}
									</div>
								</div>
								<div class="yitd">
									<img src="./assets/6.png" alt="" />
									<div class="lidd">
										<div class="tilf">
											{{$t('b44')}}
										</div>
										<div class="hex"></div>
									</div>
									<div class="coye">

										{{$t('b45')}}
									</div>
								</div>
								<div class="yitd">
									<img src="./assets/6.png" alt="" />
									<div class="lidd">
										<div class="tilf">
											{{$t('b46')}}
										</div>
										<div class="hex"></div>
									</div>
									<div class="coye">
										{{$t('b47')}}
									</div>
								</div>
								<div class="yitd">
									<img src="./assets/6.png" alt="" />
									<div class="lidd">
										<div class="tilf">
											{{$t('b48')}}
										</div>
										<div class="hex"></div>
									</div>
									<div class="coye">
										{{$t('b49')}}
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- 未来规划 -->

					<div class="nertomry" style="margin: 20px 0 0;" id="a6">

						<div class="coinmer">
							<div class="onetiele">
								<div class="orser">{{$t('b50')}}</div>
								<div class="bore"></div>
								<div class="pro"> FUTURE PLANS Future </div>
							</div>
							<div class="suder">

								<div class="zud">
									<div class="dicgh">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b51')}}</div>
										</div>
										<div class="coir">
											{{$t('b52')}}
										</div>
									</div>
									<div class="dicgh">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b53')}}</div>
										</div>
										<div class="coir">
											{{$t('b54')}}
										</div>
									</div>
									<div class="dicgh">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b55')}}</div>
										</div>
										<div class="coir">
											{{$t('b56')}}
										</div>
									</div>
								</div>

								<!--pc 映射过程 -->
								<div class="zpjh" v-if=" ispc ==true ">
									<div class="hedis">
										<div class="hgd">
											<div class="liayus">
												<div class="sy"></div>
												<div class="sy"></div>
											</div>
											<div class="guoc">
												{{$t('b57')}}
											</div>
										</div>
										<div class="jusd">
											{{$t('b64')}}
										</div>
									</div>

									<!-- 横线 -->
									<div class="pingu">
										<div class="diedr">
											<div class="yi1">
												{{$t('b58')}}
											</div>
											<div class="sgytfg">
												<div class="boret"></div>
												<div class="buyt"></div>
												<div class="boret"></div>
											</div>
											<div class="numd">01</div>
											<div class="nuconet">
												{{$t('b59')}}
											</div>
										</div>

										<div class="diedr">
											<div class="yi1">
												{{$t('b60')}}
											</div>
											<div class="sgytfg">
												<div class="boret"></div>
												<div class="buyt"></div>
												<div class="boret"></div>
											</div>
											<div class="numd">02</div>
											<div class="nuconet">
												{{$t('b61')}}
											</div>
										</div>

										<div class="diedr">
											<div class="yi1">
												{{$t('b62')}}
											</div>
											<div class="sgytfg">
												<div class="boret"></div>
												<div class="buyt"></div>
												<div class="boret"></div>
											</div>
											<div class="numd">03</div>
											<div class="nuconet">
												{{$t('b63')}}
											</div>
										</div>
									</div>
								</div>

								<!-- 移动端  映射过程 -->
								<div class="zpjh" v-if="ispc ==false ">
									<div class="hedis">
										<div class="hgd">
											<div class="liayus">
												<div class="sy"></div>
												<div class="sy"></div>
											</div>
											<div class="guoc">
												{{$t('b57')}}
											</div>
										</div>
										<div class="jusd">
											{{$t('b64')}}
										</div>
									</div>

									<!-- 横线 -->
									<div class="pingu">
										<div class="diedr">
											<div class="sgytfg">
												<div class="boret"></div>
												<div class="buyt"></div>
												<div class="boret"></div>
											</div>
											<div class="numd">01</div>
											<div class="yi1">
												{{$t('b58')}}
											</div>
											<div class="nuconet">
												{{$t('b59')}}
											</div>
										</div>

										<div class="diedr">

											<div class="sgytfg">
												<div class="boret"></div>
												<div class="buyt"></div>
												<div class="boret"></div>
											</div>
											<div class="numd">02</div>
											<div class="yi1">
												{{$t('b60')}}
											</div>
											<div class="nuconet">
												{{$t('b61')}}
											</div>
										</div>

										<div class="diedr">

											<div class="sgytfg">
												<div class="boret"></div>
												<div class="buyt"></div>
												<div class="boret"></div>
											</div>
											<div class="numd">03</div>
											<div class="yi1">
												{{$t('b62')}}
											</div>
											<div class="nuconet">
												{{$t('b63')}}
											</div>
										</div>
									</div>
								</div>

								<div class="yousid">
									<div class="thtrdis">
										<div class="dicghf">
											<div class="hetop">
												<img class="xyq" src="./assets/er.png" alt="" />
												<div class="tjd">{{$t('b65')}}</div>
											</div>
											<div class="coir">
												{{$t('b66')}}
											</div>
										</div>
									</div>
									<div class="thtrdis2">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b67')}}</div>
										</div>
										<div class="sja">
											<div class="kjd">

												<div class="shayd">

													<div class="zcsjcir">
														<div class="zj01">01</div>
														<div class="sdxj">{{$t('b68')}}</div>
													</div>
													<img class="jtypng" src="./assets/2.png" alt="" />
													<div class="zcsjcir">
														<div class="zj01">02</div>
														<div class="sdxj">{{$t('b70')}}</div>
													</div>
													<img class="jtypng" src="./assets/2.png" alt="" />
													<div class="zcsjcir">
														<div class="zj01">03</div>
														<div class="sdxj">{{$t('b72')}}</div>
													</div>
												</div>
											</div>
											<div class="ernxi">
												<div class="shad">
													{{$t('b69')}}。
												</div>
												<div class="shad">
													{{$t('b71')}}
												</div>
												<div class="shad">
													{{$t('b73')}}
												</div>
											</div>

										</div>

									</div>
								</div>

								<div class="yousid">
									<div class="ags">
										<div class="dgs">
											<div class="hetop">
												<img class="xyq" src="./assets/er.png" alt="" />
												<div class="tjd">{{$t('b74')}}</div>
											</div>
											<dir class="sabli">
												<div class="roe_on">
													<div class="on_lr">
														<div class="yqd"></div>
														<div class="jias">
															{{$t('b75')}}
														</div>
													</div>
													<div class="rig_ew">
														<div class="sdhsju">
															{{$t('b76')}}
														</div>
													</div>
												</div>
												<div class="roe_on">
													<div class="on_lr">
														<div class="yqd"></div>
														<div class="jias">
															{{$t('b77')}}
														</div>
													</div>
													<div class="rig_ew">
														<div class="sdhsju">
															{{$t('b78')}}
														</div>
													</div>
												</div>
												<div class="roe_on">
													<div class="on_lr">
														<div class="yqd"></div>
														<div class="jias">
															{{$t('b79')}}
														</div>
													</div>
													<div class="rig_ew">
														<div class="sdhsju">
															{{$t('b80')}}
														</div>
													</div>
												</div>
											</dir>
										</div>
									</div>
									<div class="thtrdis2">
										<div class="kjd">
											<div class="hetop">
												<img class="xyq" src="./assets/er.png" alt="" />
												<div class="tjd">{{$t('b81')}}</div>
											</div>
											<div class=" shdt">
												<div class="quan">
													<div class="qiuh">
														{{$t('b82')}}
													</div>
													<div class="cqjy">
														{{$t('b83')}}
													</div>
												</div>
												<div class="quan">
													<div class="qiuh">
														{{$t('b84')}}
													</div>
													<div class="cqjy">
														{{$t('b85')}}
													</div>
												</div>
												<div class="quan">
													<div class="qiuh">
														{{$t('b86')}}
													</div>
													<div class="cqjy">
														{{$t('b87')}}
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>


								<div class="zud">
									<div class="dicgh">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b88')}}</div>
										</div>
										<div class="coir">
											{{$t('b89')}}
										</div>
									</div>
									<div class="dicgh">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b90')}}</div>
										</div>
										<div class="coir">
											{{$t('b91')}}
										</div>
									</div>
									<div class="dicgh">
										<div class="hetop">
											<img class="xyq" src="./assets/er.png" alt="" />
											<div class="tjd">{{$t('b92')}}</div>
										</div>
										<div class="coir">
											{{$t('b93')}}
										</div>
									</div>
								</div>
							</div>

							<div class="dicgh3">
								<div class="hetop ccg">
									<img class="xyq" src="./assets/er.png" alt="" />
									<div class="tjd">{{$t('b94')}}</div>
								</div>
								<div class="coir">
									{{$t('b95')}}
								</div>
							</div>


						</div>
					</div>


					<!-- 高端服务 -->
					<div class="heigod" id="a7">
						<div class="coinmer">
							<div class="onetiele" style="margin: 30px 0;">
								<div class="orser">{{$t('b96')}}</div>
								<div class="bore"></div>
								<div class="pro"> HIGH END SERVICE GUARANTEE PLAN </div>
							</div>

							<div class="gadu">
								<div class="fgs" style="padding-bottom: 20px;">
									<div class="gaone">
										<img src="./assets/ert.png" alt="" />
										<div class="routi">
											<div class="tdz">
												{{$t('b97')}}
											</div>
											<div class="gaconter">
												{{$t('b98')}}
											</div>
										</div>
									</div>
								</div>

								<div class="lashg">
									<div class="gaone2">
										<div class="routi">
											<div class="tdz2">
												{{$t('b99')}}
											</div>
											<div class="gaconter">
												{{$t('b100')}}
											</div>
											<ul class="callsi">
												<li>{{$t('c1')}}</li>
												<li>{{$t('c2')}}</li>
												<li>{{$t('c3')}}</li>
												<li>{{$t('c4')}}</li>
											</ul>
										</div>
									</div>

									<div class="gaone2">
										<div class="routi">
											<div class="tdz2">
												{{$t('c5')}}
											</div>
											<div class="gaconter">
												{{$t('c6')}}
											</div>
											<ul class="callsi">
												<li>{{$t('c7')}}</li>
												<li>{{$t('c8')}}</li>
												<li>{{$t('c9')}}</li>
												<li>{{$t('c10')}}</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="lashg">
									<div class="gaone2">
										<div class="routi">
											<div class="tdz2">
												{{$t('cc11')}}
											</div>
											<div class="gaconter">
												{{$t('cc12')}}
											</div>
											<ul class="callsi">
												<li>{{$t('cc13')}}</li>
												<li>{{$t('cc14')}}</li>
												<li>{{$t('cc15')}}</li>
												<li>{{$t('c14')}}</li>
											</ul>
										</div>
									</div>

									<div class="gaone2">
										<div class="routi">
											<div class="tdz2">
												{{$t('c20')}}
											</div>
											<div class="gaconter">
												{{$t('c21')}}
											</div>
											<ul class="callsi">
												<li>{{$t('c22')}}</li>
												<li>{{$t('c23')}}</li>
												<li>{{$t('c24')}}</li>
												<li>{{$t('c25')}}</li>
											</ul>
										</div>
									</div>
								</div>


								<div class="fgs" style="padding-bottom: 20px;">
									<div class="gaone" style="border: none !important;">

										<div class="gaone2" style="border: none !important;">
											<div class="routi">
												<div class="tdz2">
													{{$t('c15')}}
												</div>
												<div class="gaconter">
													{{$t('c16')}}
												</div>
												<ul class="callsi">
													<li>{{$t('c17')}}</li>
													<li>{{$t('c18')}}</li>
													<li>{{$t('c19')}}</li>
													<!-- <li>风险评估: 提供专业的安全风险评估服务，帮助会员识别和规避潜在的安全风险。</li> -->
												</ul>
											</div>
										</div>
										<img src="./assets/lk.png" alt="" />
									</div>
								</div>

								<div class="lashg">


									<div class="gaone2">
										<div class="routi">
											<div class="tdz2">
												{{$t('c26')}}
											</div>
											<div class="gaconter">
												{{$t('c27')}}
											</div>
											<ul class="callsi">
												<li>{{$t('c28')}}</li>
												<li>{{$t('c29')}}</li>
												<li>{{$t('c30')}}</li>
												<li>{{$t('c31')}}</li>
											</ul>
										</div>
									</div>
									<div class="gaone2" style="border: none !important ;">
										<div class="routi">
											<div class="tdz2">
												{{$t('c38')}}
											</div>
											<div class="gaconter">
												{{$t('c39')}}
											</div>
											<ul class="callsi">
												<li>{{$t('c40')}}</li>
												<li>{{$t('c41')}}</li>
												<li>{{$t('c42')}}</li>
												<li>{{$t('c43')}}</li>
											</ul>
										</div>
									</div>
								</div>

								<div class="lashg">
									<div class="gaone2">
										<div class="routi">
											<div class="tdz2">
												{{$t('c32')}}
											</div>
											<div class="gaconter">
												{{$t('c33')}}
											</div>
											<ul class="callsi">
												<li>{{$t('c34')}}</li>
												<li>{{$t('c35')}}</li>
												<li>{{$t('c36')}}</li>
												<li>{{$t('c37')}}</li>
											</ul>
										</div>
									</div>

									<div class="gaone2" style="border: none !important ;">
										<div class="routi">
											<div class="tdz2">
												{{$t('c44')}}
											</div>
											<div class="gaconter">
												{{$t('c45')}}
											</div>
											<br />
											<div class="gaconter">

												<!-- 通过这种高端服务保障体系，HFLH不仅能够为会员创造更多的附加值，还能通过高利润的服务模式，为代币的销毁提供资金支持，进一步推动HFLH代币的价值稳定和增长，最终实现HFLH的长期可持续发展。 -->
											</div>

										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

					<!-- 高管目录 -->
					<div class="heiineer" id="a8">
						<div class="coinmer">
							<div class="onetiele">
								<div class="orser">{{$t('c46')}}</div>
								<div class="bore"></div>
								<div class="pro"> EXECUTIVE DIRECTORY </div>
							</div>

							<div class="thremul">
								<div class="bosrt brdash">
									<div class="nide">
										<div class="lar">
											Larry Fink
										</div>
										<div class="chanme">{{$t('c47')}}</div>
									</div>
									<div class="brfx"></div>
									<div class="jedf">
										{{$t('c48')}}
									</div>
								</div>
								<div class="bosrt brdash">
									<div class="nide">
										<div class="lar">
											Jane Smith
										</div>
										<div class="chanme">CEO</div>
									</div>
									<div class="brfx"></div>
									<div class="jedf">{{$t('c49')}}</div>
								</div>
								<div class="bosrt">
									<div class="nide">
										<div class="lar">
											Michael Brown
										</div>
										<div class="chanme">CTO</div>
									</div>
									<div class="brfx"></div>
									<div class="jedf">{{$t('c50')}}</div>
								</div>
								<div class="bosrt">
									<div class="nide">
										<div class="lar">
											Emily White
										</div>
										<div class="chanme">CFO</div>
									</div>
									<div class="brfx"></div>
									<div class="jedf">{{$t('c51')}}</div>
								</div>
							</div>
						</div>
					</div>


					<!-- 合作伙伴 -->
					<div class="hezfrie" id="a9">
						<div class="coinmer">
							<div class="onetiele">
								<div class="orser">{{$t('aa19')}}</div>
								<div class="bore"></div>
								<div class="pro">COOPERATIVE PARTNER </div>
							</div>

							<div class="frthreo">
								<div class="lsrrty">
									<div class="leimg">
										<img src="./assets/l.png" alt="" />
									</div>
									<div class="rihjsd">
										<div class="lakc">
											<div class="namllp">
												KIRKLAND & ELLIS LLP
											</div>
											<div class="hinter">
												{{$t('c52')}}
											</div>
										</div>
										<div class="xmjd">
											{{$t('c53')}}
										</div>
									</div>
								</div>
								<div class="lsrrty">
									<div class="leimg">
										<img src="./assets/m.png" alt="" />
									</div>
									<div class="rihjsd">
										<div class="lakc">
											<div class="namllp">
												MORGAN STANLEY
											</div>
											<div class="hinter">
												{{$t('c54')}}
											</div>
										</div>
										<div class="xmjd">
											{{$t('c55')}}
										</div>
									</div>
								</div>
								<div class="lsrrty">
									<div class="leimg">
										<img class="ads" src="./assets/g.png" alt="" />
									</div>
									<div class="rihjsd">
										<div class="lakc">
											<div class="namllp">
												Google Cloud
											</div>
											<div class="hinter">
												{{$t('c56')}}
											</div>
										</div>
										<div class="xmjd">
											{{$t('c57')}}
										</div>
									</div>
								</div>
								<div class="lsrrty">
									<div class="leimg">
										<img class="kkp" src="./assets/n.png" alt="" />
									</div>
									<div class="rihjsd">
										<div class="lakc">
											<div class="namllp">
												IBM BLOCKCHAIN
											</div>
											<div class="hinter">
												{{$t('c58')}}
											</div>
										</div>
										<div class="xmjd">
											{{$t('c59')}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<!-- 合作风投目录 -->

					<div class="hdft" id="a10">
						<div class="coinmer">
							<div class="onetiele">
								<div class="orser">{{$t('c60')}}</div>
								<div class="bore"></div>
								<div class="pro">COLLABORATIVE VENTURE CAPITAL DIRECTORY </div>
							</div>

							<div class="akob">
								<div class="congs">
									<div class="lowe">
										SEQUOIA CAPITAL
									</div>
									<div class="aline">
										{{$t('c61')}}
									</div>
								</div>

								<div class="congs">
									<div class="lowe">
										ANDREESSEN HOROWITZ
									</div>
									<div class="aline">
										{{$t('c62')}}
									</div>
								</div>

								<div class="congs">
									<div class="lowe">
										UNION SQUARE VENTURES
									</div>
									<div class="aline">
										{{$t('c63')}}
									</div>
								</div>
								<div class="congs">
									<div class="lowe">
										LIGHTSPEED VENTURE PARTNERS
									</div>
									<div class="aline">
										{{$t('c64')}}
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- 底部 -->


					<div class="zhlover" id="a11">
						<div class="coinmer2">
							<div class="onetiele2">
								<div class="tsh">
									<div class="tele" @click="tele">
										Telegram: https://t.me/Web3HFLH 
									</div>
								<!-- 	<div class="tele ksty">
										{{$t('c66')}}: contact@hflh.com
									</div> -->
									<div class="tele ksty" @click="tui">
										{{$t('c67')}}: https://x.com/HFLH_Official   
									</div>
									<div class="tele" @click="down" v-if=" ispc ==false ">
										{{$t('c68')}}: <div class="jks">
											{{$t('c69')}}
										</div>
									</div>

								</div>
								<div class="dow" @click="down" v-if=" ispc ==true ">
									{{$t('c68')}}: <div class="jks">
										<!-- 点击下载白皮书 -->
										<a href="https://www.hflh-x.com/pdf/chinese.pdf" v-if=" langesyu =='zhch' "
											download>{{$t('c69')}}</a>
										<a href="https://www.hflh-x.com/pdf/english.pdf" v-if=" langesyu =='en' "
											download>{{$t('c69')}}</a>
										<a href="https://www.hflh-x.com/pdf/Italy.pdf" v-if=" langesyu =='ydl' "
											download>{{$t('c69')}}</a>
										<a href="https://www.hflh-x.com/pdf/spain.pdf" v-if=" langesyu =='xby' "
											download>{{$t('c69')}}</a>
										<a href="https://www.hflh-x.com/pdf/portugal.pdf" v-if=" langesyu =='pty' "
											download>{{$t('c69')}}</a>

									</div>
								</div>
							</div>
						</div>
					</div>


					<div class="zhlover" v-if="ispc">
						<div class="coinmer2">
							<div class="jdjw">
								<div @click="langclick(0)">Chinese</div>
								<div @click="langclick(1)">English</div>
								<!-- 意大利 -->
								<div @click="langclick(2)">Italy</div>
								<!-- 西班牙 -->
								<div @click="langclick(3)">Spain</div>
								<!-- 葡萄牙 -->
								<div @click="langclick(4)">Portugal</div>

							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'animate.css';
	export default {
		data() {
			return {
				yuliat: ['简体中文', 'English', 'Italy', 'Spain', 'Portugal'],
				language: 'en',
				isihdehead: false,
				windowWidth: null,
				ispc: true,
				yddwidth: 1018, //移动端
				yuname: '简体中文',
				isayu: false,
				double: 0,
				langesyu: 'zhch',
				ishidepc:false,

			}
		},
		mounted() {
			this.language = localStorage.getItem("locale") || 'en';
			this.$i18n.locale = this.language
			const lang = this.$i18n.locale;
			this.langesyu = lang
			console.log(lang)

			if (lang == 'zhcn') {
				this.double = 0
				this.yuname = '简体中文'
			}
			if (lang == 'en') {
				this.double = 1
				this.yuname = 'English'
			}
			if (lang == 'ydl') {
				this.double = 2
				this.yuname = 'Italy'
			}
			if (lang == 'xby') {
				this.double = 3
				this.yuname = 'Spain'
			}
			if (lang == 'pty') {
				this.double = 4
				this.yuname = 'Portugal'
			}

			this.windowWidth = window.innerWidth;
			window.addEventListener('resize', this.handleResize);
			// console.log(this.windowWidth)
			if (this.windowWidth > this.yddwidth) {
				this.ispc = true
			} else {
				this.ispc = false
			}

		},

		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			gosele(){
			this.ishidepc = !this.ishidepc	
			},
			zhegsg() {
				this.isayu = false
				this.ishidepc = false
			},
			changeclsoe() {
				this.isayu = !this.isayu
			},
			langclick(i) {
				console.log(i)
				this.isayu = !this.isayu
				this.double = i
				if (i == 0) {
					this.yuname = '简体中文'
					this.language = 'zhcn'

					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.langesyu = lang
					return;
				}
				if (i == 1) {
					this.yuname = 'English'
					this.language = 'en'
					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.langesyu = lang
					return

				}
				if (i == 2) {
					this.yuname = 'Italy'
					this.language = 'ydl'
					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.langesyu = lang
					return
				}
				if (i == 3) {
					this.language = 'xby'
					this.yuname = 'Spain'
					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.langesyu = lang
					return
				}
				if (i == 4) {
					this.language = 'pty'
					this.yuname = 'Portugal'
					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.langesyu = lang
					return
				}





			},
			handleSelection() {
				// 使用 this.selectedValue 作为调用接口的参数
				console.log("Selected value for API call:", this.selectedValue);
				// 这里添加调用接口的代码
			},
			tele() {
				var roUrls = 'https://t.me/Web3HFLH'
				window.open(roUrls, '_blank');
			},
			tui() {
				var roUrls = 'https://x.com/HFLH_Official'
				window.open(roUrls, '_blank');
			},
			down() {
				var roUrls = 'https://twitter.com/HFLH'
				window.open(roUrls, '_blank');
			},
			gosan() {
				this.isihdehead = !this.isihdehead
			},

			handleResize() {
				this.windowWidth = window.innerWidth;
				// console.log(this.windowWidth)

				if (this.windowWidth > this.yddwidth) {
					this.ispc = true
					this.isihdehead = false
				} else {
					this.ispc = false
				}

			},
		}
	}
</script>

<style lang="scss">
	
	.ydlia {
		display: flex;
	}
	
	.seley {
		background-color: #fff;
		position: absolute;
	
		.ones {
			font-size: 14px;
			color: #000;
		}
	}
	
	.borez {
		border-left: 2px solid #c53c22;
		color: #c53c22 !important;
	}

	.tele {
		cursor: pointer;
	}

	a {
		text-decoration: none;
		color: #BABABA;
	}

	// 合作风投
	.congs {
		margin: 25px 0;
	}

	.lowe {
		font-weight: bold;
		font-size: 14px;
		color: #000000;
	}

	.aline {
		font-size: 14px;
		color: #000000;
		padding-top: 6px;
	}

	// 768   1018
	@media only screen and (min-width: 1018px) {
		
		.huygd{
			position: relative;
		}
		.jya {
		
			// position: absolute;
			// top: 0;
			// right: 0;
			width: 100px;
			padding: 6px 0;
			text-align: center;
			box-shadow: 5px 5px 5px #e3d1d14d;
			/* 添加阴影 */
		
			background-color: #fff;
		
			.vhs {
				margin: 10px 0;
				height: 20px;
				line-height: 20px;
				// padding:0 0 10px 0;
				color: #000;
			}
		}
		
		.head_may {
			position: absolute;
			left: -58px;;
			top: 20px;
			// background-color: #000;
			// width: 300px;
			// height: 60px;
			padding: 20px 0;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		
		}
		
		.jdjw {
			width: 600px;
			colofont-size: 14px;
			color: #BABABA;
			line-height: 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			div {
				cursor: pointer;
			}
		}

		.coinmer2 {
			width: 80%;
			// height: 50px;
			margin: 0 auto;
		}

		.onetiele2 {
			height: 80px;
			// margin: 30px 0 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.zhlover {
			height: 80px;
			line-height: 80px;
			background-color: #000000;
		}

		.tsh {
			display: flex;

		}

		.ksty {
			margin: 0 60px;
		}

		.jks {
			border-bottom: 1px solid #838383;
		}

		.dow {
			font-size: 14px;
			color: #BABABA;
			line-height: 24px;
			display: flex;
		}

		.tele {
			font-size: 14px;
			color: #BABABA;
			line-height: 24px;
		}

		// 合作伙伴
		.kkp {
			width: 114px !important;
			height: 46px !important;
		}

		.ads {
			width: 90px !important;
			height: 87px !important;
		}

		.frthreo {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.lsrrty {
			width: 47%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F3F3F3;
			padding: 30px 10px;
			margin-bottom: 30px;
		}

		.rihjsd {
			width: 54%;
		}

		.leimg {
			width: 40%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 180px;
				height: 31px;
			}
		}

		.xmjd {
			padding: 10px 0;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.lakc {
			width: 80%;
			border-bottom: 1px dashed #999;

			.hinter {
				font-size: 14px;
				color: #000000;
				line-height: 18px;
				padding: 10px 0;
			}

			.namllp {
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
				line-height: 18px;
			}
		}

		// 高管目录
		.brdash {
			border-bottom: 1px dashed rgba(215, 215, 215, 1);

		}

		.thremul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.bosrt {
			width: 48%;

			.lar {
				margin-top: 20px;
				font-weight: bold;
				font-size: 18px;
				color: #000000;
				line-height: 18px;
			}

			.chanme {
				margin: 6px 0;
				font-size: 14px;
				color: #000000;
				line-height: 18px;
			}

			.brfx {
				margin: 16px 0;
				width: 20px;
				height: 1px;
				background: #ef9684;
				border: 1px solid #ef9684
			}

			.jedf {
				padding-bottom: 20px;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}
		}

		// 高端服务
		.heigod {
			margin-top: 40px;
		}

		.fgs {
			width: 47%;
		}

		.lashg {
			width: 47%;
		}

		.gadu {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.routi {
			ul {
				list-style-position: inside;
				padding-left: 0;
				/* 可以移除整个列表的左侧内边距，但保留项目符号的位置 */
			}

			li {
				padding: 0;
				font-size: 14px;
				color: #000000;
				line-height: 1.8;
			}

			.tdz {
				margin: 30px 0 20px;
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
			}

			.tdz2 {
				// margin: 30px 0 20px;
				// margin-bottom: 30px;
				padding-bottom: 20px;
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
			}

			.gaconter {
				font-size: 14px;
				color: #000000;
				line-height: 1.8;
			}
		}

		.gaone2 {
			width: 100%;
			padding-bottom: 10px;
			margin-bottom: 20px;
			border-bottom: 1px solid rgba(215, 215, 215, 1);

			img {
				width: 100%;
			}
		}

		.gaone {
			width: 100%;
			padding-bottom: 30px;
			border-bottom: 1px solid rgba(215, 215, 215, 1);

			img {
				width: 100%;
				height: 272px;
			}
		}



		.ccg {
			width: 20% !important;
			align-self: flex-start;
		}

		.dicgh3 {
			padding: 30px 16px 20px 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.suder {
			padding-bottom: 20px;
			border-bottom: 1px dashed rgba(222, 37, 0, 1);
		}

		.shdt {
			width: 90%;
			margin: 10px auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			align-items: stretch;
		}

		.quan {
			width: 26%;
			padding: 20px;
			border: 1px solid #DE2500;

			.cqjy {
				padding: 10px 0;
				text-align: center;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}

			.qiuh {
				text-align: center;
				font-weight: bold;
				font-size: 14px;
				color: #DE2500;
				line-height: 30px;
				padding-bottom: 6px;
				border-bottom: 1px dashed #DE2500;
				;
			}
		}

		// 映射
		.on_lr {
			align-self: flex-start;
		}

		.sabli {
			width: 100%;
			margin: 0 !important;
			padding: 0 !important;
		}

		.rig_ew {
			width: 60%;
		}

		.ags {
			width: 30%;
			background: #F7F7F7;
			padding: 20px 16px;
		}

		.roe_on {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 10px 0;
		}

		.sdhsju {
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.on_lr {
			width: 30%;
			display: flex;
			align-items: center;

			.yqd {
				background-color: rgba(222, 37, 0, 1);
				width: 9px;
				height: 9px;
				border-radius: 50%;
				margin-right: 10px;
			}

			.jias {
				font-size: 14px;
				color: #000000;
				line-height: 30px;
			}
		}

		.ernxi {
			width: 90%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.shad {
			width: 25%;
			text-align: center;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.yousid {
			display: flex;
			align-items: center;
			justify-content: space-between;
			align-items: stretch;
			margin: 30px 0;
		}

		.thtrdis {
			width: 30%;
			background-color: #F7F7F7;
			padding: 20px 16px;

			.dicghf {
				width: 100% !important;
			}
		}

		.thtrdis2 {
			width: 63%;
			background-color: #F7F7F7;
			padding: 20px;

		}

		.shayd {
			width: 90%;
			margin: 10px auto;
			display: flex;
			align-items: center;
			justify-content: space-around;


		}

		.sdxj {
			font-size: 14px;
			color: #FFFFFF;
			line-height: 24px;
		}

		.zj01 {
			font-weight: 400;
			font-size: 24px;
			color: #FFFFFF;
			line-height: 24px;
		}

		.zcsjcir {
			background-color: #de2500;
			border-radius: 50%;
			width: 121px;
			height: 121px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

		}

		.jtypng {
			width: 61px;
			height: 20px;
		}

		.numd {
			text-align: center;
			font-size: 18px;
			color: #FFFFFF;
			line-height: 24px;
		}

		.nuconet {
			width: 81%;
			text-align: center;
			margin: 0 auto;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 24px;
		}

		.pingu {
			width: 95%;
			margin: 80px auto 20px;
			display: flex;
			align-items: center;
			align-items: stretch;
		}

		.sgytfg {
			margin: 10px 0;
			display: flex;
			align-items: center;

			.boret {
				width: calc(100% * .5);
				flex-shrink: 1;
				height: 1px;
				background: #d0cdee;
			}

			.buyt {
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: #fff;
			}
		}

		.diedr {
			width: 33%;

			.yi1 {
				text-align: center;
				font-size: 18px;
				color: #FFFFFF;
				line-height: 24px;
			}
		}

		.jusd {
			font-size: 14px;
			color: #FFFFFF;
			line-height: 24px;
			text-align: center;
			margin: 20px 0;
		}

		.hgd {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.guoc {
			font-weight: bold;
			font-size: 18px;
			color: #FFFFFF;

		}

		.zpjh {
			margin: 30px 0;
			padding: 30px 0;
			width: 100%;
			height: 320px;
			background: url('~@/assets/r.png') no-repeat;
			background-size: 100% 100%;
			// /* 设置背景图片，确保url路径正确指向你的图片 */
			// background-image: url('~@/assets/r.png');
			// /* 假设你的图片在src/assets目录下 */

			// /* 设置其他样式，比如背景大小和位置 */
			// background-size: cover;
			// background-position: center;

		}

		.liayus {
			display: flex;
			margin-right: 20px;

			.sy {
				width: 21px;
				height: 21px;
				border-radius: 50%;
				border: 1px solid #fff;
				margin-left: -10px;
			}
		}

		// 未来规划
		.zud {
			display: flex;
			align-items: center;
			justify-content: space-between;
			align-items: stretch;
		}

		.dicgh {
			width: 30%;
			background: #F7F7F7;
			padding: 20px 16px;
		}

		.hetop {
			padding-bottom: 16px;
			display: flex;
			align-items: center;
		}

		.xyq {
			width: 31px;
			height: 21px;
			padding-right: 20px;
		}

		.tjd {
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.coir {
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}


		// 项目持续性
		.coyd {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.yitd {
			width: 17%;
			padding: 8px 10px;
			border-left: 1px solid rgba(215, 215, 215, 1);

			.coye {
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}

			.lidd {
				margin: 25px 0;

				.tilf {
					font-weight: bold;
					font-size: 18px;
					color: #DE2500;
					line-height: 18px;
				}

				.hex {
					margin: 20px 0;
					width: 22px;
					height: 1px;
					background-color: #DE2500;
				}
			}

			img {
				width: 70px;
				height: 69px;
			}
		}

		// 项目安全性
		.ldkf {
			margin: 0 30px;
		}

		.theewd {
			display: flex;
			align-items: end;
			justify-content: center;
			align-items: stretch
		}

		.heyus {
			width: 26%;
			background-color: #fff;


			.jisoev {
				text-align: center;
				padding: 20px 40px;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}

			img {
				width: 100%;
				height: 198px;
			}

			.lais {
				display: flex;
				flex-direction: column;
				align-items: center;

				.hysd {
					padding: 20px 0;
					font-weight: bold;
					font-size: 18px;
					color: #000000;
					line-height: 18px;
				}

			}

			.hex {
				width: 40px;
				height: 2px;
				background: #7f7f7f;
			}
		}


		.secure {
			padding: 30px 0;
			background: #f7f7f7;
		}


		// 项目优势
		.suodk {
			margin: 20px 0;

			.tha {
				// width: 96px;
				width: max-content;
				height: 24px;
				line-height: 24px;
				padding: 3px 14px;
				background: #F0F0F0;
				font-weight: 400;
				font-size: 14px;
				color: #000000;
			}

			.neid {
				margin: 10px 0;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}
		}

		.gouyx {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.goue {
				width: 18%;
			}

			.coter {
				font-size: 14px;
				color: #999999;
				line-height: 24px;
				padding: 10px 0 15px;

				border-bottom: 1px solid #cfcfcf;
			}

			.content {
				width: 100%;
				// height: 80px;
				display: flex;
				margin: 40px auto;
				position: relative;
			}

			.line {
				width: 70px;
				height: 1px;
				background: rgba(222, 37, 0, 1);
				transform: rotateZ(-41deg);

			}

			.left {
				position: absolute;
				left: 10px;
				top: -25px;
				font-weight: bold;
				font-size: 26px;
				color: #DE2500;
				line-height: 18px;
			}

			.right {
				position: absolute;
				left: 36px;
				top: 7px;
				font-weight: bold;
				font-size: 18px;
				color: #000000;
				line-height: 18px;
			}
		}

		// 项目制度
		.xiah {
			width: 63%;
		}

		.boed {
			display: flex;
			align-items: center;
			justify-content: space-between;

			align-items: stretch;
		}

		.bgs {
			width: 29%;
			// height: 97vh;
			/* 设置背景图片，确保url路径正确指向你的图片 */
			background-image: url('~@/assets/zs.png');
			/* 假设你的图片在src/assets目录下 */

			/* 设置其他样式，比如背景大小和位置 */
			background-size: cover;
			background-position: center;

			/* 其他需要的样式 */

			/* 或者你需要的高度 */
		}

		.hans {
			font-size: 14px;
			color: #000000;
		}

		.mr {
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.circle {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			border: 1px solid #000;
		}

		.circles {
			padding: 40px 0;
			margin-left: 10px;
			display: flex;
			// justify-content: center;
			// align-items: center;
		}

		.circle {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: -7px;
		}

		.has {
			padding: 6px;
			background: #f7f7f7;
		}

		.achyiya_toptre {
			display: flex;
			align-items: center;
			justify-content: space-between;
			align-items: stretch;

			.jdai {

				width: 29%;

				padding: 20px 20px;
				// height: 80vh;
			}
		}

		.tiod {
			margin: 10px 0;
		}

		.alis {


			li {
				line-height: 30px;
				padding: 3px 0;

				list-style-type: disc;
				/* 设置列表项标记类型为实心圆 */
				list-style-position: outside;
				/* 设置标记位于内容外侧 */
				list-style-color: rgba(222, 37, 0, 1);
			}


			ul>li {
				font-weight: 400;
				font-size: 13px;
				color: #000000;


			}

			ul {
				list-style-type: none;
				/* 去掉项目符号 */
				padding-left: 16px;
				/* 去掉左侧内边距 */
				// margin: 0; /* 去掉默认的上下外边距 */

			}

			// ul.no-sign li {
			//   text-indent: -1em; /* 如果需要，可以缩进文本以填补掉项目符号原来的位置 */
			// }

		}

		.jdai {
			border: 1px solid #ea745d;

			.zys {
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
			}

			.tiod {
				font-weight: 400;
				font-size: 14px;
				color: #666666;
				line-height: 24px;
			}
		}


		// 与资本共同成长
		.aljs {
			padding: 20px 0;
			display: flex;
			justify-content: space-between;
		}

		.topsj {

			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.lizj {
			width: 15%;
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.conlw {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			border-bottom: 1px dashed #cbcbcb;
		}

		.coninlist {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}

		.two_lis {
			width: 47%;
			padding: 20px 12px 0;
			// height: 220px;
			margin-bottom: 20px;
			background-color: #f7f7f7;
		}

		.halia {
			display: flex;
			flex-direction: column;
		}

		.tilna {
			width: max-content;
			display: block;
			padding: 6px 10px;
			background: rgba(255, 223, 217, 1);
			font-weight: 400;
			font-size: 18px;
			color: #000000;
			line-height: 18px;

		}

		.acout {
			margin: 10px 0;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.two_kai {
			margin-bottom: 20px;
			width: 49%;
			display: flex;
			align-items: flex-end;

			img {
				width: 100%;
				height: 212px;
			}
		}


		.thres {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.binae {
				width: 20%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;

				.cfd {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-around;
				}

				.a1 {
					width: 31px;
					height: 33px;
				}

				.nmas {
					padding: 5px 0;
					font-weight: 400;
					font-size: 14px;
					color: #000000;
				}

				.xjd {
					width: 80%;
					word-break: break-all;
					text-align: center;
					font-weight: 400;
					font-size: 13px;
					color: #000000;
				}
			}
		}

		.one_left {
			height: 100%;
			align-items: flex-start;
			width: 15%;

		}

		.one_right {
			height: 100%;
			align-items: flex-start;
			width: 80%;
			display: flex;
			justify-content: space-between;
		}

		.blo {
			// width: 200px;
			max-width: 180px;
			height: 110px;
			// height: 120px;
			vertical-align: middle;
		}

		.xmbg {}

		.xjtd {
			align-self: center;
			width: 12px;
			height: 12px;
		}

		.bjh {
			padding: 20px 0 40px;
			margin-bottom: 10px;
			// height: 140px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #ebebeb;
		}

		.cotejs {
			width: 70%;
			font-family: SourceHanSansCN, SourceHanSansCN;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 30px;
		}

		.xmbg {
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.onetiele {
			margin: 30px 0 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.orser {
				flex-grow: 0.1;
				font-weight: bold;
				font-size: 24px;
				color: #000000;
				text-align: left;
			}

			.bore {
				flex-grow: 3;
				height: 1px;
				background-color: #cdcdcd;
			}

			.pro {

				flex-grow: 0.1;
				font-weight: bold;
				font-size: 12px;
				color: #DE2500;
				text-align: right;
			}
		}

		.coinmer {
			width: 80%;
			// height: 50px;
			margin: 30px auto;
		}


		.contercom {
			background: #000000;
			widows: 100%;
			height: 67px;
		}

		.login {
			width: 40px;
			height: 40px;
		}

		.ishow {
			display: block;
		}

		.ishide {
			display: none;
		}

		.bired {
			display: none;
		}

		.contercom {
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.deli {
			text-align: center;
			font-weight: 400;
			font-size: 16px;
			color: #BABABA;
			cursor: pointer;
		}
	}

	//当屏幕最大宽度为767px    1018
	@media screen and (max-width:1018px) {
		.jya {
		
			position: absolute;
			top: 30px;
			width: 100px;
			padding: 6px 0;
			text-align: center;
			box-shadow: 5px 5px 5px #e3d1d14d;
			/* 添加阴影 */
		
			background-color: #fff;
		
			.vhs {
				margin: 10px 0;
				height: 20px;
				line-height: 20px;
				// padding:0 0 10px 0;
				color: #000;
			}
		}
		
		.head_may {
			background-color: #000;
			width: 90%;
			// height: 60px;
			padding: 20px 0;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
		
		}
		
		.jdjw {
			padding-bottom: 60px;
			width: 100%;
			colofont-size: 14px;
			color: #BABABA;
			line-height: 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			div {
				cursor: pointer;
			}
		}

		.coinmer2 {
			width: 80%;
			// height: 50px;
			margin: 0 auto;
		}

		.onetiele2 {
			padding: 20px 0;
			// height: 80px;
			// margin: 30px 0 20px 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
		}

		.zhlover {
			// height: 80px;
			// line-height: 80px;
			background-color: #000000;
		}

		.tsh {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

		}

		.jks {
			border-bottom: 1px solid #838383;
		}

		.dow {
			font-size: 14px;
			color: #BABABA;
			line-height: 24px;
			display: flex;
		}

		.tele {
			width: 100%;
			padding: 6px 0;
			text-align: left;
			font-size: 14px;
			color: #BABABA;
			display: flex;
			line-height: 24px;
		}

		// 合作伙伴
		.kkp {
			width: 114px !important;
			height: 46px !important;
		}

		.ads {
			width: 90px !important;
			height: 87px !important;
		}

		.frthreo {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.lsrrty {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #F3F3F3;
			padding: 30px 10px;
			margin-bottom: 30px;
		}

		.rihjsd {
			width: 54%;
		}

		.leimg {
			width: 40%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100px;
				height: 26px;
			}
		}

		.xmjd {
			padding: 10px 0;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.lakc {
			width: 80%;
			border-bottom: 1px dashed #999;

			.hinter {
				font-size: 14px;
				color: #000000;
				line-height: 18px;
				padding: 10px 0;
			}

			.namllp {
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
				line-height: 18px;
			}
		}

		// 高管目录
		.brdash {
			border-bottom: 1px dashed rgba(215, 215, 215, 1);

		}

		.thremul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.bosrt {
			width: 48%;

			.lar {
				margin-top: 20px;
				font-weight: bold;
				font-size: 18px;
				color: #000000;
				line-height: 18px;
			}

			.chanme {
				margin: 6px 0;
				font-size: 14px;
				color: #000000;
				line-height: 18px;
			}

			.brfx {
				margin: 16px 0;
				width: 20px;
				height: 1px;
				background: #ef9684;
				border: 1px solid #ef9684
			}

			.jedf {
				padding-bottom: 20px;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}
		}

		// 高端服务
		.heigod {
			margin-top: 40px;
		}

		.fgs {
			width: 100%;
		}

		.lashg {
			width: 100%;
		}

		.gadu {
			display: flex;
			justify-content: space-between;
			// flex-wrap: wrap;
			flex-direction: column;

		}

		.routi {
			ul {
				list-style-position: inside;
				padding-left: 0;
				/* 可以移除整个列表的左侧内边距，但保留项目符号的位置 */
			}

			li {
				padding: 0;
				font-size: 14px;
				color: #000000;
				line-height: 1.8;
			}

			.tdz {
				margin: 30px 0 20px;
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
			}

			.tdz2 {
				// margin: 30px 0 20px;
				// margin-bottom: 30px;
				padding-bottom: 20px;
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
			}

			.gaconter {
				font-size: 14px;
				color: #000000;
				line-height: 1.8;
			}
		}

		.gaone2 {
			width: 100%;
			padding-bottom: 10px;
			margin-bottom: 20px;
			border-bottom: 1px solid rgba(215, 215, 215, 1);

			img {
				width: 100%;
			}
		}

		.gaone {
			width: 100%;
			padding-bottom: 30px;
			border-bottom: 1px solid rgba(215, 215, 215, 1);

			img {
				width: 100%;
				height: 180px;
			}
		}



		.ccg {
			width: 20% !important;
			align-self: flex-start;
		}

		.dicgh3 {
			padding: 30px 16px 20px 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.suder {
			padding-bottom: 20px;
			border-bottom: 1px dashed rgba(222, 37, 0, 1);
		}

		.shdt {
			width: 90%;
			margin: 10px auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			align-items: stretch;
		}








		// 未来规划

		.ccg {
			width: 100% !important;
			align-self: flex-start;
		}

		.dicgh3 {
			padding: 20px 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
		}

		.suder {
			padding-bottom: 20px;
			border-bottom: 1px dashed rgba(222, 37, 0, 1);
		}

		.shdt {
			width: 90%;
			margin: 10px auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-direction: column;
		}

		.quan {
			// width: 26%;
			padding: 20px 30px;
			border: 1px solid #DE2500;
			margin-bottom: 20px;

			.cqjy {
				padding: 10px 0;
				text-align: center;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}

			.qiuh {
				text-align: center;
				font-weight: bold;
				font-size: 14px;
				color: #DE2500;
				line-height: 30px;
				padding-bottom: 6px;
				border-bottom: 1px dashed #DE2500;
				;
			}
		}

		// 映射
		.on_lr {
			align-self: flex-start;
		}

		.sabli {
			width: 100%;
			margin: 0 !important;
			padding: 0 !important;
		}

		.rig_ew {
			width: 60%;
		}

		.ags {
			width: 100%;
			background: #F7F7F7;
			padding: 20px 16px;
			margin-bottom: 20px;
		}

		.roe_on {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0;
		}

		.sdhsju {
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.on_lr {
			width: 37%;
			display: flex;
			align-items: center;

			.yqd {
				background-color: rgba(222, 37, 0, 1);
				width: 9px;
				height: 9px;
				border-radius: 50%;
				margin-right: 10px;
				flex-shrink: 0;
			}

			.jias {
				font-size: 14px;
				color: #000000;
				line-height: 30px;
			}
		}

		.ernxi {
			height: 440px;
			width: 90%;
			// margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
		}

		.shad {
			padding-left: 6px;
			// width: 25%;
			text-align: left;
			height: 30%;
			// background: #ccc;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
			display: flex;
			align-items: center;
		}

		.yousid {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
			margin: 30px 0;
		}

		.thtrdis {
			width: 100%;
			background-color: #F7F7F7;
			padding: 20px 16px;
			margin-bottom: 20px;

			.dicghf {
				width: 100% !important;
			}
		}

		.thtrdis2 {
			width: 100%;
			background-color: #F7F7F7;
			padding: 20px 16px;

		}

		.shayd {
			margin: 10px auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-direction: column;


		}

		.sdxj {
			font-size: 12px;
			color: #FFFFFF;
			// line-height: 24px;
		}

		.zj01 {
			font-size: 16px;
			color: #FFFFFF;
			// line-height: 24px;
		}

		.zcsjcir {
			background-color: #de2500;
			border-radius: 50%;
			width: 100px;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

		}

		.sja {
			display: flex;
		}

		.jtypng {
			margin: 20px 0;
			width: 31px;
			height: 20px;
			transform: rotate(90deg);
		}

		.numd {
			margin: 0 4px;
			text-align: center;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 24px;
		}

		.nuconet {
			width: 50%;
			text-align: center;
			margin: 0 auto;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 24px;
		}

		.pingu {
			width: 95%;
			margin: 80px auto 20px;
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		.sgytfg {
			// margin: 10px 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			// transform: rotate(90deg);

			.boret {
				// width: calc(100% * .5);
				width: 2px;
				flex-shrink: 1;
				height: 60px;
				background: #d0cdee;
			}

			.buyt {
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: #fff;
			}
		}

		.diedr {
			width: 100%;
			display: flex;
			align-items: center;

			.yi1 {
				// width: 90%;
				text-align: center;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 24px;
			}
		}

		.jusd {
			font-size: 14px;
			width: 90%;

			color: #FFFFFF;
			line-height: 24px;
			text-align: center;
			margin: 20px auto;
		}

		.hgd {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.guoc {
			font-weight: bold;
			font-size: 18px;
			color: #FFFFFF;

		}

		.zpjh {
			margin: 30px 0;
			padding: 30px 0;
			width: 100%;
			// height: 320px;
			background: url('~@/assets/mi.png') no-repeat;
			background-size: 100% 100%;
			// /* 设置背景图片，确保url路径正确指向你的图片 */
			// background-image: url('~@/assets/r.png');
			// /* 假设你的图片在src/assets目录下 */

			// /* 设置其他样式，比如背景大小和位置 */
			// background-size: cover;
			// background-position: center;

		}

		.liayus {
			display: flex;
			margin-right: 20px;

			.sy {
				width: 21px;
				height: 21px;
				border-radius: 50%;
				border: 1px solid #fff;
				margin-left: -10px;
			}
		}

		// 未来规划
		.zud {
			display: flex;
			align-items: center;
			justify-content: space-between;

			flex-direction: column;
		}

		.dicgh {
			width: 100%;
			background: #F7F7F7;
			padding: 20px 16px;
			margin-bottom: 20px;
		}

		.hetop {
			padding-bottom: 16px;
			display: flex;
			align-items: center;
		}

		.xyq {
			width: 31px;
			height: 21px;
			padding-right: 20px;
		}

		.tjd {
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.coir {
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		// 项目持续性
		.coyd {
			display: flex;
			align-items: center;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.yitd {
			width: 100%;
			padding: 8px 10px;
			border-left: 1px solid rgba(215, 215, 215, 1);
			margin: 10px 0;

			.coye {
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}

			.lidd {
				margin: 25px 0;

				.tilf {
					font-weight: bold;
					font-size: 18px;
					color: #DE2500;
					line-height: 18px;
				}

				.hex {
					margin: 20px 0;
					width: 22px;
					height: 1px;
					background-color: #DE2500;
				}
			}

			img {
				width: 70px;
				height: 69px;
			}
		}

		// 项目安全性
		.ldkf {
			margin: 30px 0;
		}

		.theewd {
			display: flex;
			// align-items: end;
			flex-direction: column;
			justify-content: center;
			// align-items: stretch
		}

		.heyus {
			width: 100%;
			background-color: #fff;


			.jisoev {
				text-align: center;
				padding: 20px 40px;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}

			img {
				width: 100%;
				height: 198px;
			}

			.lais {
				display: flex;
				flex-direction: column;
				align-items: center;

				.hysd {
					padding: 20px 0;
					font-weight: bold;
					font-size: 18px;
					color: #000000;
					line-height: 18px;
				}

			}

			.hex {
				width: 40px;
				height: 2px;
				background: #7f7f7f;
			}
		}


		.secure {
			padding: 30px 0;
			background: #f7f7f7;
		}

		// 项目优势
		.suodk {
			margin: 20px 0;

			.tha {
				// width: 96px;
				width: max-content;
				height: 24px;
				line-height: 24px;
				padding: 3px 14px;
				background: #F0F0F0;
				font-weight: 400;
				font-size: 14px;
				color: #000000;
			}

			.neid {
				margin: 10px 0;
				font-size: 14px;
				color: #000000;
				line-height: 24px;
			}
		}

		.gouyx {
			width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;

			.goue {
				width: 47%;
			}

			.coter {
				font-size: 14px;
				color: #999999;
				line-height: 24px;
				padding: 10px 0 15px;

				border-bottom: 1px solid #cfcfcf;
			}

			.content {
				width: 100%;
				// height: 80px;
				display: flex;
				margin: 40px auto;
				position: relative;
			}

			.line {
				width: 70px;
				height: 1px;
				background: rgba(222, 37, 0, 1);
				transform: rotateZ(-41deg);

			}

			.left {
				position: absolute;
				left: 10px;
				top: -25px;
				font-weight: bold;
				font-size: 26px;
				color: #DE2500;
				line-height: 18px;
			}

			.right {
				position: absolute;
				left: 36px;
				top: 7px;
				font-weight: bold;
				font-size: 18px;
				color: #000000;
				line-height: 18px;
			}
		}

		// 项目制度
		.xiah {
			width: 100%;
		}

		.boed {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;

			// align-items: stretch;
		}

		.bgs {
			width: 100%;
			// height: 97vh;
			/* 设置背景图片，确保url路径正确指向你的图片 */
			background-image: url('~@/assets/zs.png');
			/* 假设你的图片在src/assets目录下 */

			/* 设置其他样式，比如背景大小和位置 */
			background-size: cover;
			background-position: center;

			/* 其他需要的样式 */

			/* 或者你需要的高度 */
		}

		.hays {
			display: flex;

			li {
				padding-right: 20px !important;
			}
		}

		.bgs2 {
			width: 100%;
			// height: 97vh;
			/* 设置背景图片，确保url路径正确指向你的图片 */
			background-image: url('~@/assets/8.png');
			/* 假设你的图片在src/assets目录下 */

			/* 设置其他样式，比如背景大小和位置 */
			// background-size: cover;
			// background-position: center;

			// background: url('@/static/index/18.png') no-repeat;
			background-size: 100% 100%;

			/* 其他需要的样式 */

			/* 或者你需要的高度 */
		}

		.hans {
			font-size: 14px;
			color: #000000;
		}

		.mr {
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.circle {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			border: 1px solid #000;
		}

		.circles {
			padding: 40px 0;
			margin-left: 10px;
			display: flex;
			// justify-content: center;
			// align-items: center;
		}

		.circle {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: -7px;
		}

		.has {
			padding: 6px;
			background: #f7f7f7;
		}

		.achyiya_toptre {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
			// align-items: stretch;

			.jdai {

				width: 100%;

				padding: 20px 20px;
				margin-bottom: 30px;
				// height: 80vh;
			}
		}

		.tiod {
			margin: 10px 0;
		}

		.alis {


			li {
				line-height: 30px;
				padding: 3px 0;

				list-style-type: disc;
				/* 设置列表项标记类型为实心圆 */
				list-style-position: outside;
				/* 设置标记位于内容外侧 */
				list-style-color: rgba(222, 37, 0, 1);
			}


			ul>li {
				font-weight: 400;
				font-size: 13px;
				color: #000000;


			}

			ul {
				list-style-type: none;
				/* 去掉项目符号 */
				padding-left: 16px;
				/* 去掉左侧内边距 */
				// margin: 0; /* 去掉默认的上下外边距 */

			}

			// ul.no-sign li {
			//   text-indent: -1em; /* 如果需要，可以缩进文本以填补掉项目符号原来的位置 */
			// }

		}

		.jdai {
			border: 1px solid #ea745d;

			.zys {
				font-weight: bold;
				font-size: 18px;
				color: #DE2500;
			}

			.tiod {
				font-weight: 400;
				font-size: 14px;
				color: #666666;
				line-height: 24px;
			}
		}

		// 与资本共同成长
		.aljs {
			padding: 20px 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.topsj {

			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.lizj {
			margin: 5px 0 10px 0;
			width: 15%;
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.conlw {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			border-bottom: 1px dashed #cbcbcb;
		}

		.coninlist {
			display: flex;
			// align-items: flex-end;
			flex-direction: column;
			justify-content: space-between;
		}

		.two_lis {
			// width: 100%;
			padding: 20px 10px 0;
			// height: 220px;
			margin-bottom: 20px;
			background-color: #f7f7f7;
		}

		.halia {
			display: flex;
			flex-direction: column;
		}

		.tilna {
			width: max-content;
			// display: block;
			padding: 6px 10px;
			background: rgba(255, 223, 217, 1);
			font-weight: 400;
			font-size: 16px;
			color: #000000;
			line-height: 18px;

		}

		.acout {
			margin: 10px 0;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 24px;
		}

		.two_kai {
			margin-bottom: 20px;
			width: 100%;
			display: flex;
			align-items: flex-end;

			img {
				width: 100%;
				height: 212px;
			}
		}


		.thres {
			width: 100%;
			display: flex;
			// align-items: center;
			flex-direction: column;
			justify-content: space-between;

			.binae {
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.cfd {
					margin-left: 20px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}

				.a1 {
					width: 31px;
					height: 33px;
				}

				.nmas {
					padding: 5px 0;
					font-weight: 400;
					font-size: 14px;
					color: #000000;
				}

				.xjd {

					// word-break: break-all;
					text-align: left;
					font-weight: 400;
					font-size: 12px;
					color: #000000;
				}
			}
		}

		.one_left {
			height: 100%;
			align-items: flex-start;
			padding: 10px 0;
			// width: 15%;

		}

		.one_right {
			height: 100%;
			// align-items: flex-start;
			// width: 90%;
			display: flex;
			flex-direction: column;
			// align-items: center;
			justify-content: space-between;

		}

		.blo {
			width: 100%;
			// max-width: 180px;
			height: 250px;
			// height: 120px;
			vertical-align: middle;
			margin: 6px 0;
		}

		.xmbg {}

		.xjtd {
			display: none;
		}

		.bjh {
			padding: 20px 0 20px;
			margin-bottom: 10px;
			// height: 140px;
			display: flex;
			flex-direction: column;
			// align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #ebebeb;
		}

		.cotejs {
			// width: 70%;
			font-family: SourceHanSansCN, SourceHanSansCN;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
			line-height: 30px;
		}

		.xmbg {
			font-weight: bold;
			font-size: 18px;
			color: #DE2500;
		}

		.onetiele {
			margin: 30px 0 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.orser {
				flex-grow: 0.1;
				font-weight: bold;
				font-size: 18px;
				color: #000000;
				text-align: left;
			}

			.bore {
				flex-grow: 3;
				height: 1px;
				background-color: #cdcdcd;
			}

			.pro {

				flex-grow: 0.1;
				font-weight: bold;
				font-size: 12px;
				color: #DE2500;
				text-align: right;
			}
		}

		.coinmer {
			width: 80%;
			// height: 50px;
			margin: 30px auto;
		}

		.deli2 {
			width: 100%;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid rgba(51, 51, 51, .1);

			.text {
				font-weight: 500;
				font-size: 18px;
				color: #000000;
			}
		}

		.hts {
			width: 10px;
			height: 16px;
		}

		.login {
			width: 35x;
			height: 35px;
		}

		.ishide {
			display: block;
			background: #000000;
			width: 100%;
			height: 60px;
		}


		.maishead {
			width: 90%;
			margin: 0 auto;
			// display: flex;
			// flex-direction: column;
			// align-items: center;

		}

		.ishow {
			display: none;
		}



		.bired {
			width: 24px;
			height: 24px;
		}
	}




	/* 清除图片默认边框 */
	img {
		border: none;
	}


	.sange {

		margin-right: 30px;

		img {
			width: 16px;
			height: 16px;
			margin-left: 6px;
		}

		.yuy {
			position: relative;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #fff;
		}
	}


	body {
		margin: 0;
		padding: 0;
		background: #fff;

	}
</style>
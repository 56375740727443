const pty = {
	a1: "Introdução ao projeto",
	aa11: "Crescimento conjunto com o capital",
	aa12: "Regras do projeto",
	aa13: "Vantagens do projeto",
	aa14: "Segurança do projeto",
	aa15: "Sustentabilidade do projeto",
	aa16: "Planos futuros",
	aa17: "Plano de garantia de serviços premium",
	aa18: "Diretório de executivos",
	aa19: "Parceiros",
	aa121: "Diretório de investidores",
	aa122: "Contato",

	a2:"Introdução ao projeto",
	a3:"Fundo e fundação do projeto",
	a4:"A Fundação Future Horizons foi fundada em 2021, com sede em Singapura. É uma organização sem fins lucrativos focada em pesquisa e aplicação de tecnologia blockchain. A fundação está comprometida em promover o desenvolvimento e a popularização da tecnologia blockchain, visando criar um mundo digital mais transparente, seguro e eficiente por meio da inovação tecnológica e cooperação.",
	a5:"Introdução e distribuição de tokens",
	a6:"Nome do token",
	a7:"Quantidade inicial emitida",
	a8:"Cem mil milhões",
	a9:"Regras de emissão",
	a10:"Aposte 100BNB para obter 75BNB em tokens.",
	a11:"Devolva o HFLH para recuperar o BNB apostado",
	a12:"Retirar o BNB depositado e queimar todos os tokens correspondentes.",
	a13:"Uso do token",
	a14:"Cem milhões de tokens, juntamente com o BNB inicial fornecido pela fundação, irão para o pool de negociação.",
	a15:"Objetivo do projeto",
	a16:"O objetivo da Fundação Future Horizons é promover o desenvolvimento e a aplicação da tecnologia blockchain através da inovação tecnológica e cooperação, promovendo o progresso e a prosperidade da sociedade. Os objetivos específicos incluem inovação tecnológica, popularização de aplicações, formação de talentos e responsabilidade social.",
	a17:"Objetivo de criar uma fonte de riqueza",
	a18:"O objetivo principal do projeto HFLH é se tornar uma fonte de riqueza para todos os participantes. Isso significa que os participantes não apenas obterão rendimentos iniciais, mas também poderão obter rendimentos contínuos através da participação a longo prazo. Ao projetar um ecossistema de criptomoedas estável e promissor, o HFLH visa criar uma plataforma de riqueza sustentável e de crescimento constante para todos os membros.",
	a19:"Lógica de criação de valor de criptomoedas",
	a20:"A essência das criptomoedas reside em representar valor. Quando o preço do HFLH é de 1 BNB e o volume total é de cem milhões, o valor de mercado total é de cem milhões de BNB. Para os membros que possuem HFLH, isso significa que a quantidade de tokens que possuem reflete diretamente sua riqueza. Por exemplo, se um membro possui 1000 HFLH, desde que haja liquidez suficiente no mercado, ele pode trocá-los por 1000 BNB a qualquer momento. Portanto, quanto mais membros possuem HFLH, mais ativo é o mercado e mais estável é o valor do HFLH. Neste caso, o valor trazido pelo consenso torna-se evidente. Através de ampla participação no mercado e vontade de manter os tokens, o HFLH pode alcançar um valor estável e obter reconhecimento do mercado. A formação deste consenso depende não apenas do aumento da quantidade de tokens mantidos, mas também da confiança contínua do mercado no valor do HFLH.",
	a21:"Base para um consenso forte e duradouro",
	a22:"O sucesso do HFLH depende da construção de um consenso de mercado forte e duradouro. No entanto, confiar apenas na ideia não é suficiente para alcançar esse objetivo. O mecanismo de empréstimo com depósito oferece um forte suporte de valor para o HFLH. Quando o preço dos tokens comuns no mercado cai, geralmente resulta em vendas de pânico, levando a uma queda ilimitada no preço dos tokens ou mesmo a zero. Através do método de empréstimo com depósito, os membros podem obter novos tokens enquanto garantem a segurança do capital. Especificamente, quando um membro deposita 100 BNB, ele pode obter tokens HFLH no valor de 75 BNB. Se o preço do token não subir mais de um terço, esse membro não venderá facilmente os tokens que possui. Esse mecanismo garante a paciência dos novos participantes, mesmo que o preço do token flutue, eles não venderão facilmente os tokens. Ao mesmo tempo, quando o preço do token cai, os membros podem retirar o empréstimo e recuperar o BNB, sem precisar vender HFLH no mercado, evitando eventos de pânico.",
	a23:"Valorização contínua do mercado e rendimentos dos participantes",
	a24:"O valor de mercado do HFLH inevitavelmente aumentará com o reconhecimento do mercado e o aumento do número de participantes. A fundação distribuirá o crescimento do valor de mercado para todos os participantes de várias maneiras, permitindo que eles compartilhem os rendimentos do crescimento do HFLH. Este mecanismo de compartilhamento de rendimentos não só atrai mais participantes, mas também aumenta a confiança dos membros existentes, promovendo ainda mais a atividade do mercado. Ao criar valor rapidamente e distribuí-lo a todos os participantes, o HFLH pode formar um ciclo virtuoso: o consenso do mercado aumenta, o valor de mercado aumenta, mais participantes ingressam, o que, por sua vez, estabiliza e eleva ainda mais o valor do HFLH.",
	a25:"Crescimento conjunto com o capital",
	a26:"Participantes e crescimento do valor de mercado do HFLH",
	a27:"À medida que o número de participantes do HFLH continua a crescer, o valor de mercado também aumenta. Esse crescimento forma um ciclo positivo, pois mais participantes significam mais demanda no mercado, impulsionando o preço do token. O crescimento do valor de mercado não é apenas um aumento no preço do token, mas também inclui a confiança e as expectativas do mercado sobre as perspectivas futuras do projeto. A estabilidade do crescimento do valor de mercado é um fator importante para atrair investimentos de grande capital, pois o capital considera a estabilidade do mercado e o potencial de crescimento ao tomar decisões de investimento.",
	a28:"Investimento de capital e estabilidade de mercado",
	a30:"O investimento de capital não só traz mais entrada de fundos, mas também aumenta o reconhecimento e a influência do HFLH no mercado. Quanto mais capital investido, maior é a necessidade de estabilidade e velocidade de crescimento do valor de mercado do HFLH. Grandes capitais geralmente escolhem investir em projetos com valor de mercado estável e potencial de crescimento sustentável, garantindo a segurança e o potencial de valorização de seus fundos. Para o HFLH, desde que mantenha um crescimento estável no valor de mercado, pode continuar a atrair a atenção e o investimento de grandes capitais.",
	a31:"Estratégia de valorização contínua",
	a32:"O capital ao participar do projeto HFLH, não está interessado em obter retornos de curto prazo vendendo tokens. Em vez disso, eles focam na valorização do valor de mercado a longo prazo. A valorização contínua dos tokens HFLH significa que o valor dos tokens mantidos pelo capital também está aumentando, o que não só aumenta o valor de mercado do capital, mas também eleva o preço das ações do capital devido ao aumento do reconhecimento do mercado. Portanto, manter tokens HFLH e desfrutar dos rendimentos a longo prazo torna-se a principal estratégia dos grandes capitais.",
	a33:"Estabilidade do valor de mercado e confiança do capital",
	a34:"O sucesso do projeto HFLH depende da manutenção de um crescimento estável no valor de mercado. Essa estabilidade não vem apenas do aumento do número de participantes, mas também da confiança do mercado nas perspectivas futuras do HFLH. Através do aprimoramento contínuo dos mecanismos do projeto, aumento da transparência do mercado e da atividade dos participantes, o HFLH pode estabelecer um forte sistema de confiança no mercado. Desde que o HFLH prove ter potencial de crescimento estável e sustentável, o capital terá confiança para manter seus tokens a longo prazo, resultando em crescimento contínuo do valor de mercado.",
	a35:"Diversificação dos participantes do mercado",
	a36:"À medida que o projeto HFLH se desenvolve, os participantes do mercado não se limitarão apenas a investidores individuais, mas também incluirão investidores institucionais e grandes capitais. Essa estrutura diversificada de participantes pode trazer mais apoio de mercado e entrada de capital para o HFLH. A entrada de investidores institucionais e grandes capitais não só aumenta a influência do projeto no mercado, mas também, através de suas estratégias de investimento profissionais e recursos, ajuda o HFLH a expandir o mercado e aumentar o valor de mercado mais rapidamente.",
	a37:"Resumo",
	a38:"O projeto HFLH, através do aumento contínuo de participantes e do crescimento do valor de mercado, atrai o investimento de grandes capitais e, através da estratégia de valorização contínua, aumenta o valor de mercado do capital. Desde que o HFLH consiga manter um crescimento estável no valor de mercado, a confiança dos participantes do mercado aumentará continuamente, e o capital estará mais disposto a manter seus tokens a longo prazo, promovendo o desenvolvimento contínuo do projeto e o aumento constante do valor de mercado.",
	a40:"Através deste mecanismo, o HFLH não só pode maximizar seu próprio valor de mercado, mas também fornecer uma fonte estável de rendimentos para os participantes e o capital, formando um ciclo virtuoso e promovendo o desenvolvimento sustentável a longo prazo do projeto.",
	a50:"Regras do projeto",
	a51:"Empréstimo com depósito",
	a52:"O projeto HFLH permite que os membros depositem BNB a preços de mercado para tomar emprestado tokens. As regras específicas são as seguintes:",
	a53:"Os membros podem depositar 100 BNB para tomar emprestado tokens no valor de 75 BNB (por exemplo).",
	a54:"Quando os membros devolvem os tokens emprestados ao contrato, eles podem resgatar o depósito de 100 BNB na íntegra.",
	a55:"Cada endereço pode ter até cinco empréstimos simultâneos.",
	a56:"Esse mecanismo não só fornece liquidez, mas também garante que os membros possam obter tokens depositando BNB conforme necessário para participar de mais atividades do projeto.",
	a57:"A quantidade inicial de emissão é de cem milhões de tokens. A única forma de aumentar o número total de tokens posteriormente é através do empréstimo com depósito de BNB. Os tokens emprestados são novos tokens emitidos. Quando os membros devolvem os tokens e recuperam o BNB, os tokens são queimados. Para cada novo token emitido, há um BNB correspondente como garantia.",
	a60:"Mineração por depósito",
	a61:"O projeto HFLH incentiva os membros a realizar mineração depositando seus tokens. Este sistema será interrompido quando o valor total de mercado atingir 20 milhões de BNB. Os parâmetros abaixo serão ajustados conforme necessário de acordo com as condições do mercado, e novos parâmetros só afetarão novos depósitos, sem impacto nos depósitos já realizados. As regras específicas são as seguintes:",
	a62:"Os membros podem realizar mineração uma vez por dia após depositar seus tokens.",
	a63:"A quantidade de mineração está positivamente correlacionada com a quantidade de tokens depositados.",
	a64:"Existem três opções de período de depósito: 10 dias, 30 dias e 90 dias.",
	a65:"O rendimento diário da mineração para um depósito de 10 dias é de 0,1%, mas a quantidade de tokens depositados não é considerada para cálculo de membros efetivos e não gera rendimento para superiores ou agentes.",
	a66:"O rendimento diário da mineração para um depósito de 30 dias é de 0,2%, e a quantidade de tokens depositados pode ser usada como base para cálculo de membros efetivos e gerar rendimento para superiores.",
	a67:"O rendimento diário da mineração para um depósito de 90 dias é de 0,3%, e a quantidade de tokens depositados pode ser usada como base para cálculo de membros efetivos e gerar rendimento para superiores.",
	a68:"Este mecanismo incentiva os membros a manterem tokens a longo prazo, ao mesmo tempo que fornece um suprimento estável de tokens para o projeto, promovendo o desenvolvimento saudável do ecossistema.",
	a69:"Ao realizar mineração por depósito, os membros podem especificar um endereço de retorno do principal, para que, ao retirar o principal, ele seja retornado ao endereço especificado.",
	a70:"Rendimento da equipe",
	a71:"A fundação oferece recompensas de indicação para todos os membros. As regras específicas do mecanismo de recompensa de indicação são as seguintes:",
	a72:"Após a mineração por depósito de uma vez alcançar 0,02 BNB (baseado no preço de mercado dos tokens no momento do depósito), membros que recomendarem amigos a participarem da mineração por depósito podem receber rendimento em três níveis.",
	a73:"Primeiro nível 10%",
	a74:"Segundo nível 5%",
	a75:"Terceiro nível 3%",
	a76:"Quando os indicados nos três níveis realizam mineração por depósito, o indicante pode obter rendimento direto em tokens.",
	a77:"Este mecanismo não só incentiva os membros a promoverem ativamente o projeto, mas também aumenta a participação no projeto e a base de usuários.",
	a78:"Rendimento de agentes",
	a79:"A fundação oferece um mecanismo de recompensa generosa para agentes. Este sistema será encerrado quando o número de membros atingir 30 milhões. As regras específicas são as seguintes:",
	a80:"Os níveis de agentes são divididos em sete. Novos membros são agentes de nível um.",
	a81:"Membro efetivo: mineração por depósito de uma vez alcançando 0,2 BNB, baseado no preço de mercado dos tokens no momento do depósito. Múltiplos depósitos são permitidos, contanto que um deles alcance 0,2 BNB para ser considerado um membro efetivo.",
	a82:"O número de membros efetivos de nível inferior em três níveis deve aumentar para o número especificado para a promoção do agente até o nível seis. Por exemplo, se o número de membros efetivos de nível inferior em três níveis atingir 100, o agente é promovido ao nível dois. Se atingir 300, é promovido ao nível três.",
	a83:"Rendimento de agentes:",
	a84:"Agente de nível um (menos de 100 membros efetivos de nível inferior em três níveis): Para cada novo membro efetivo, receber 0,02 BNB em tokens.",
	a85:"Agente de nível dois (menos de 300 membros efetivos de nível inferior em três níveis): Para cada novo membro efetivo, receber 0,024 BNB em tokens (0,004 BNB a mais do que o agente de nível um).",
	a86:"Agente de nível três (menos de 500 membros efetivos de nível inferior em três níveis): Para cada novo membro efetivo, receber 0,028 BNB em tokens (0,004 BNB a mais do que o agente de nível dois).",
	a87:"Agente de nível quatro (menos de 700 membros efetivos de nível inferior em três níveis): Para cada novo membro efetivo, receber 0,032 BNB em tokens (0,004 BNB a mais do que o agente de nível três).",
	a88:"Agente de nível cinco (menos de 1000 membros efetivos de nível inferior em três níveis): Para cada novo membro efetivo, receber 0,036 BNB em tokens (0,004 BNB a mais do que o agente de nível quatro).",
	a89:"Agente de nível seis (menos de 1500 membros efetivos de nível inferior em três níveis): Para cada novo membro efetivo, receber 0,04 BNB em tokens (0,004 BNB a mais do que o agente de nível cinco).",
	a90:"Forma de pagamento do rendimento:",
	a91:"Para cada novo membro efetivo, é concedida imediatamente metade do rendimento. Por exemplo, um agente de nível um com um novo membro efetivo receberá imediatamente 0,01 BNB em tokens.",
	a92:"Quando se atingir o centésimo novo membro de nível inferior, o agente receberá todo o rendimento restante para esse nível. Por exemplo, quando o agente de nível um atingir o centésimo novo membro, ele receberá 1,01 BNB em tokens (0,01 BNB + 100 * 0,01 BNB) e será promovido ao nível dois.",
	a93:"Quando o número de membros efetivos de nível inferior atingir 1500 para o agente de nível seis, todo o rendimento de agente será concedido. Não haverá mais rendimento para novos membros além do 1501.",
	a94:"Nota importante",
	a95:"Quando o número de membros efetivos de nível inferior atingir 1500, o agente se tornará acionista. Para cada novo membro efetivo em toda a rede, o sistema emitirá tokens no valor de 0,02 BNB e os acumulará como rendimento para os acionistas.",
	a96:"Quando o número de membros efetivos atingir 30 milhões, todos os acionistas dividirão de uma só vez todos os rendimentos acumulados anteriormente.",
	a97:"Configuração de Slippage",
	a98:"Compra ",
	a99:"Venda ",
	a100:"Retirada ",
	b1:"Vantagens do projeto",
	b2:" Rendimento sem risco",
	b3:"O projeto HFLH permite que os membros obtenham tokens por meio de empréstimo com depósito. Seja mantendo ou realizando mineração por depósito, não há risco de perda. As vantagens específicas são as seguintes:",
	b4:"Segurança do capital:",
	b5:"Se o preço do token cair, basta devolver os tokens emprestados ao contrato para garantir a segurança do capital.",
	b6:"Rendimento sem risco:",
	b7:"Participar da mineração por depósito garante rendimentos sem risco, mesmo se o preço do token cair.",
	b8:" Benefícios em queda de preço",
	b9:"O projeto HFLH permite inovadoramente que os usuários obtenham benefícios mesmo quando o preço do token cai. As vantagens específicas são as seguintes:",
	b10:"Mecanismo de venda a descoberto:",
	b11:"Os usuários podem vender os tokens emprestados e comprá-los de volta quando o preço cair, devolvendo-os ao contrato e recuperando o capital.",
	b12:"Lucro bidirecional:",
	b13:"Os usuários podem obter lucro não apenas com o aumento do preço do token, mas também com a queda. Esta é uma grande inovação no projeto DeFi, diferindo dos projetos tradicionais que só permitem comprar e manter.",
	b14:"Arquitetura distribuída",
	b15:"O projeto HFLH adota uma arquitetura de rede distribuída, com todos os dados e operações distribuídos em múltiplos nós ao redor do mundo. As vantagens específicas são as seguintes:",
	b16:"Sem ponto único de falha:",
	b17:"Mesmo se alguns nós falharem, o sistema continua a funcionar normalmente.",
	b18:"Alta disponibilidade:",
	b19:"A falha de qualquer nó individual não afeta a operação do projeto como um todo, garantindo a continuidade e estabilidade do projeto.",
	b20:"Código aberto e transparente",
	b21:"O código do projeto HFLH é de código aberto, permitindo que qualquer pessoa o revise, copie e utilize. As vantagens específicas são as seguintes:",
	b22:"Transparência:",
	b23:"Todas as regras e operações são públicas e transparentes, aumentando a confiança dos usuários no projeto.",
	b24:"Manutenção pela comunidade:",
	b25:"Mesmo que a equipe original abandone o projeto, os membros da comunidade ainda podem continuar a manter e desenvolver o projeto, garantindo a sustentabilidade do projeto.",
	b26:"Restrição de contrato inteligente",
	b27:"As regras de operação do projeto HFLH são claramente definidas em contratos inteligentes, e todos os participantes, incluindo os responsáveis pelo projeto, só podem operar dentro dessas regras. As vantagens específicas são as seguintes:",
	b28:"Regras predeterminadas:",
	b29:"Qualquer operação além das regras estabelecidas será automaticamente rejeitada pelo sistema, garantindo a justiça e transparência do projeto.",
	b30:"Restrição de regras:",
	b31:"O contrato inteligente é em si um sistema de regras, e qualquer operação deve estar em conformidade com as condições predeterminadas do contrato, sem a possibilidade de violar as regras especificadas antecipadamente.",
	b32:"Segurança do projeto",
	b33:"Transparência do contrato inteligente",
	b34:"O projeto HFLH depende de contratos inteligentes na blockchain, que são totalmente abertos e transparentes. Qualquer pessoa pode revisar e auditar o código do contrato, garantindo que ele seja executado de acordo com as regras predeterminadas. Essa transparência reduz significativamente a possibilidade de ações maliciosas e fraudes, aumentando a confiança dos usuários no projeto.",
	b35:"Execução automática",
	b36:"Os contratos inteligentes do projeto HFLH são executados automaticamente quando certas condições são atendidas, sem necessidade de intervenção humana. Essa automação não só aumenta a eficiência das operações, mas também reduz o risco de erros humanos e falhas operacionais, garantindo a precisão e consistência na execução do contrato.",
	b37:"Alta segurança",
	b38:"O projeto HFLH é baseado na tecnologia blockchain, que utiliza um ledger distribuído e um mecanismo de consenso para garantir a segurança e a inviolabilidade dos dados. A natureza descentralizada da blockchain torna quase impossível a alteração dos registros de transações, garantindo a segurança e confiabilidade do projeto.",
	b39:"Sustentabilidade do projeto",
	b40:"Arquitetura distribuída",
	b41:"O projeto HFLH adota uma arquitetura de rede distribuída, com todos os dados e operações distribuídos em múltiplos nós ao redor do mundo. Isso significa que, mesmo se alguns nós falharem, o sistema continuará funcionando normalmente, sem risco de falha de ponto único ou encerramento repentino.",
	b42:"Renúncia completa de direitos",
	b43:"Após o lançamento do projeto HFLH, os responsáveis pelo projeto renunciarão completamente ao controle do projeto. Uma vez que os contratos inteligentes sejam implantados na blockchain, eles não podem ser alterados ou excluídos, garantindo que os responsáveis pelo projeto não possam fechar ou alterar unilateralmente as regras de operação do projeto.",
	b44:"Contrato inalterável",
	b45:"Uma vez que os contratos inteligentes do projeto HFLH são implantados na blockchain, eles são imutáveis. Isso significa que os responsáveis pelo projeto não podem fechar ou alterar arbitrariamente as regras de operação do projeto. Tudo deve ser executado de acordo com o contrato, garantindo a transparência e a justiça do projeto.",
	b46:"Código aberto",
	b47:"O código do projeto HFLH é de código aberto, permitindo que qualquer pessoa o revise, copie e utilize. Isso limita ainda mais o controle dos responsáveis pelo projeto. Mesmo que a equipe original abandone o projeto, os membros da comunidade podem continuar a manter e desenvolver o projeto, garantindo sua sustentabilidade.",
	b48:"Sem ponto único de falha",
	b49:"Devido à dependência do projeto HFLH em uma rede distribuída, não há risco de falha de ponto único. Mesmo que um nó falhe, os outros nós continuarão a operar e manter a estabilidade da rede, garantindo que o projeto não feche repentinamente.",
	b50:"Planos futuros",
	b51:"Planejamento de bolsa de valores",
	b52:"O projeto HFLH planeja, em cooperação com a maior firma de advocacia do mundo, Kirkland & Ellis LLP, e baseado em seus acordos legais abrangentes, construir uma bolsa de valores avançada. Esta bolsa permitirá a interconexão de propriedades reais e ativos digitais, tornando a negociação de vários ativos mais rápida e segura.",
	b53:"Parceiros e garantias legais",
	b54:"A Kirkland & Ellis LLP é uma das principais firmas de advocacia do mundo, com vasta experiência em lidar com questões jurídicas internacionais e uma equipe jurídica forte. A cooperação com a Kirkland & Ellis LLP garantirá a legalidade e eficácia de todas as transações e acordos legais do HFLH. Essa parceria proporcionará uma sólida garantia legal para o funcionamento da bolsa de valores HFLH, permitindo que ela opere legalmente e com segurança em todo o mundo.",
	b55:"Mapeamento e negociação de ativos",
	b56:"Na bolsa de valores, propriedades reais serão mapeadas em ativos digitais por meio de meios digitais. Esses ativos digitais incluem, mas não se limitam a, várias propriedades, automóveis, minerais, ouro, fazendas e empresas. Através da tecnologia blockchain, a propriedade e o histórico de transações desses ativos serão registrados e armazenados de forma segura, garantindo a transparência e a imutabilidade dos ativos.",
	b57:"Processo de mapeamento de ativos digitais",
	b58:"Avaliação e certificação de ativos",
	b59:"Primeiro, os ativos reais serão avaliados e certificados profissionalmente para garantir a precisão e legalidade de seu valor.",
	b60:"Mapeamento digital",
	b61:"Os ativos avaliados serão mapeados como ativos digitais correspondentes e as informações de propriedade serão registradas na blockchain.",
	b62:"Listagem e negociação",
	b63:"Os ativos digitais serão listados na bolsa de valores para que os usuários negociem.",
	b64:"Esse método de mapeamento de ativos digitais não só melhora a eficiência das transações de ativos, mas também aumenta a transparência e segurança das negociações.",
	b65:"Negociação rápida e proteção de privacidade",
	b66:"Outra característica importante da bolsa de valores é a capacidade de realizar transações rápidas de vários ativos. Os usuários podem comprar, vender, transferir e hipotecar ativos a qualquer momento e em qualquer lugar. Em comparação com os métodos tradicionais de registro offshore e de posse oculta, a bolsa de valores oferece uma forma mais discreta de posse de propriedades.",
	b67:"Processo de transação rápida",
	b68:"Listagem de ativos",
	b69:"Os usuários podem listar seus ativos na bolsa para venda ou aluguel.",
	b70:"Negociação em tempo real",
	b71:"Através de um sistema avançado de correspondência, os usuários podem realizar transações de ativos em tempo real, garantindo alta eficiência e conclusão rápida das transações.",
	b72:"Liquidação de fundos",
	b73:"Após a conclusão da transação, os fundos serão automaticamente liquidados na conta do usuário, garantindo a segurança e pontualidade dos fundos.",
	b74:"Mecanismo de proteção de privacidade",
	b75:"Criptografia de dados",
	b76:"Todos os dados de transação e informações dos usuários serão criptografados para impedir vazamento de dados e acesso não autorizado.",
	b77:"Transação anônima",
	b78:"Suporte a transações anônimas, permitindo que os usuários optem por não divulgar suas informações de identidade, aumentando a privacidade das transações.",
	b79:"Proteção de segurança em múltiplos níveis",
	b80:"Por meio de mecanismos de proteção de segurança em múltiplos níveis, garantimos a segurança dos ativos e informações dos usuários.",
	b81:"Vantagens da bolsa de valores",
	b82:"Plataforma de negociação global",
	b83:"A bolsa de valores será uma plataforma de negociação global, atraindo usuários e capitais de todo o mundo. Com tecnologia avançada e garantias legais abrangentes, a bolsa oferecerá aos usuários um ambiente de negociação de ativos seguro, conveniente e eficiente.",
	b84:"Negociação de ativos diversificados",
	b85:"Seja imóveis, automóveis, minerais, ouro, fazendas ou empresas, a bolsa de valores pode realizar negociações de vários tipos de ativos. Os usuários podem comprar e vender livremente vários tipos de ativos na plataforma, enriquecendo significativamente as opções de negociação de ativos.",
	b86:"Uso eficiente de capital",
	b87:"Através da bolsa de valores, os usuários podem realizar uma utilização eficiente de seus ativos. Além de obter rendimentos por meio de venda e aluguel de ativos, também é possível obter mais suporte financeiro por meio de hipoteca de ativos e financiamento. Isso fornece aos usuários mais liquidez de capital, aumentando a eficiência do uso dos ativos.",
	b88:"Perspectivas futuras",
	b89:"À medida que a bolsa de valores HFLH continua a se desenvolver e melhorar, ela se tornará a principal plataforma de negociação de ativos globalmente. Com a introdução contínua de tecnologia avançada e garantias legais abrangentes, a bolsa de valores continuará a melhorar a experiência do usuário e a eficiência das negociações, atraindo mais usuários e capitais.",
	b90:"Inovação e desenvolvimento tecnológico",
	b91:"No futuro, o HFLH continuará explorando e introduzindo mais tecnologias inovadoras, como inteligência artificial e análise de big data, para melhorar o nível de inteligência e automação das negociações. Essas tecnologias ajudarão os usuários a gerenciar melhor seus ativos e tomar decisões de negociação, aumentando a eficiência geral das transações.",
	b92:"Expansão de mercado e cooperação",
	b93:"O HFLH expandirá ativamente o mercado global e buscará mais oportunidades de cooperação internacional. Através de parcerias com instituições financeiras de renome, instituições legais e empresas de tecnologia em todo o mundo, o HFLH aumentará ainda mais sua influência internacional e competitividade no mercado.",
	b94:"Conclusão",
	b95:"O estabelecimento da bolsa de valores HFLH fornecerá aos usuários uma plataforma de negociação de ativos segura, eficiente e discreta. Através da cooperação com a Kirkland & Ellis LLP e baseada em acordos legais abrangentes e tecnologia avançada, o HFLH permitirá a interconexão de propriedades reais e ativos digitais, promovendo o processo de digitalização de transações de ativos globais. No futuro, com o desenvolvimento e melhoria contínuos da plataforma, o HFLH se tornará líder no campo de negociação de ativos globais, fornecendo serviços de alta qualidade e mais oportunidades de investimento para usuários e capitais.",
	b96:"Plano de garantia de serviços premium",
	b97:"Visão geral dos serviços premium personalizados",
	b98:"A fundação está empenhada em fornecer serviços personalizados abrangentes e premium aos membros. Esses serviços não apenas cobrem todos os aspectos da vida diária, mas também incluem algumas demandas premium, garantindo que os membros possam desfrutar da mais alta qualidade de vida. Ao fornecer esses serviços premium, o HFLH não apenas cria mais valor agregado para os membros, mas também gera um modelo de serviço de alta lucratividade. Esses serviços premium serão um passo crucial para a valorização do HFLH, pois a maior parte dos lucros gerados será usada para queima de tokens HFLH, promovendo a estabilidade e crescimento do valor dos tokens.",
	b99:"Alimentos premium seguros",
	b100:"No campo da alimentação, o HFLH fornecerá aos membros serviços alimentares seguros, saudáveis e premium. Os detalhes incluem:",

	c1:"Alimentos orgânicos: Membros podem desfrutar de alimentos orgânicos de fazendas orgânicas de classe mundial, garantindo ingredientes naturais e saudáveis.",
	c2:"Refeições nutricionais personalizadas: Baseado na condição de saúde e nas preferências alimentares dos membros, oferecemos planos de refeições personalizados para garantir uma ingestão nutricional equilibrada diária.",
	c3:"Culinária internacional: Oferecemos culinária premium de todo o mundo para satisfazer os gostos variados dos membros.",
	c4:"Chef particular: Oferecemos serviço de chef particular, onde o chef prepara refeições em casa para garantir frescor e qualidade.",
	c5:" Aquisição e gestão de propriedades",
	c6:"O HFLH fornecerá serviços abrangentes de aquisição e gestão de propriedades para garantir a segurança e valorização dos ativos dos membros. Os serviços específicos incluem:",
	c7:" Imóveis globais: Os membros podem comprar propriedades premium em todo o mundo, incluindo mansões, apartamentos e resorts.",
	c8:"Avaliação profissional: Oferecemos serviços profissionais de avaliação imobiliária para garantir a precisão da avaliação de valor, ajudando os membros a tomar as melhores decisões de investimento.",
	c9:"Empréstimos para compra de imóveis: Oferecemos suporte para empréstimos para compra de imóveis, ajudando os membros a realizar a compra da propriedade dos seus sonhos.",
	c10:"Gestão de propriedades: Oferecemos serviços completos de gestão de propriedades, incluindo manutenção de imóveis, gestão de locação e estratégias de valorização de ativos.",
	cc11:"Configuração e manutenção de veículos",
	cc12:"Para atender às necessidades de transporte premium dos membros, o HFLH fornecerá serviços completos de configuração e manutenção de veículos. Os detalhes incluem:",
	cc13:"Configuração de carros de luxo: Oferecemos serviços de compra e personalização de carros de luxo, incluindo marcas de carros de luxo de classe mundial.",
	cc14:"Serviços de iate: Oferecemos serviços de compra, personalização, atracação e manutenção de iates, garantindo que os membros desfrutem da experiência de luxo no mar.",
	cc15:"Avião particular: Oferecemos serviços de compra, aluguel e manutenção de aviões particulares, garantindo segurança e conforto nas viagens aéreas dos membros.",
	c14:"Manutenção profissional: Oferecemos serviços de manutenção e reparo regulares para carros de luxo, iates e aviões particulares, garantindo que todos os veículos estejam sempre em perfeitas condições.",
	c15:" Serviços de mordomo premium",
	c16:"O HFLH fornecerá serviços de mordomo premium para garantir que a vida diária dos membros seja organizada. Os serviços específicos incluem:",
	c17:"Mordomo particular: Oferecemos serviços de mordomo particular, que gerenciará os assuntos diários dos membros, incluindo agendamento de compromissos, organização de eventos e gestão da vida.",
	c18:"Serviços domésticos: Oferecemos serviços domésticos abrangentes, incluindo limpeza, lavanderia e manutenção de residências.",
	c19:"Assistente pessoal: Oferecemos serviços de assistente pessoal para ajudar os membros a lidar com assuntos diários e situações de emergência, garantindo uma vida sem preocupações.",
	c20:" Segurança",
	c21:"O HFLH dá grande importância à segurança dos membros e fornecerá serviços de segurança abrangentes para garantir a segurança de vidas e propriedades. Os serviços específicos incluem:",
	c22:"Guarda-costas particular: Oferecemos serviços profissionais de guarda-costas particulares, altamente qualificados em segurança e com vasta experiência.",
	c23:"Monitoramento de segurança: Oferecemos sistemas avançados de monitoramento de segurança para garantir a segurança do ambiente residencial dos membros.",
	c24:"Resgate de emergência: Oferecemos serviços de resgate de emergência 24 horas, incluindo primeiros socorros e gestão de emergências.",
	c25:"Avaliação de risco: Oferecemos serviços profissionais de avaliação de risco de segurança para ajudar os membros a identificar e evitar riscos potenciais de segurança.",
	c26:"Consultoria financeira",
	c27:"O HFLH fornecerá serviços profissionais de consultoria financeira para ajudar os membros a planejar suas finanças e gerenciar seus ativos de forma científica. Os serviços específicos incluem:",
	c28:"Consultoria de investimentos: Oferecemos serviços profissionais de consultoria de investimentos para ajudar os membros a elaborar estratégias de investimento personalizadas e alcançar a valorização segura dos ativos.",
	c29:"Consultoria tributária: Oferecemos serviços de consultoria tributária para ajudar os membros a planejar seus impostos de forma racional e minimizar a carga tributária.",
	c30:"Planejamento financeiro: Oferecemos serviços abrangentes de planejamento financeiro, incluindo planejamento de aposentadoria, planejamento patrimonial e fundos para a educação dos filhos.",
	c31:"Gestão de ativos: Oferecemos serviços profissionais de gestão de ativos para ajudar os membros a otimizar a alocação de seus ativos e alcançar valorização.",
	c32:" Educação dos filhos",
	c33:"O HFLH valoriza muito a educação dos filhos dos membros e fornecerá apoio educacional abrangente para garantir o desenvolvimento completo das crianças dos membros. Os serviços específicos incluem:",
	c34:"Escolas de elite: Ajudamos os filhos dos membros a ingressarem nas principais escolas do mundo, incluindo escolas internacionais e universidades de prestígio.",
	c35:"Consultoria educacional: Oferecemos serviços profissionais de consultoria educacional para ajudar os membros a elaborar planos de educação para seus filhos.",
	c36:"Apoio acadêmico: Oferecemos serviços de apoio acadêmico em todas as disciplinas para ajudar os filhos dos membros a obterem excelentes resultados acadêmicos.",
	c37:"Atividades extracurriculares: Organizamos uma ampla variedade de atividades extracurriculares e clubes de interesse para promover o desenvolvimento abrangente dos filhos dos membros.",
	c38:"Modelo de serviços de alta qualidade e alta lucratividade",
	c39:"Os serviços premium do HFLH focarão em qualidade superior e alta lucratividade para garantir a máxima qualidade de serviço e alto valor agregado. As medidas específicas incluem:",
	c40:"Seleção rigorosa: Realizamos uma seleção rigorosa de todos os fornecedores de serviços para garantir a qualidade e segurança dos serviços.",
    c41:"Personalização: Fornecemos serviços personalizados de acordo com as necessidades dos membros, garantindo precisão e atenção aos detalhes.",
	c42:"Alto valor agregado: Através da oferta de serviços de alto valor agregado, obtemos altos lucros, garantindo a sustentabilidade e estabilidade dos serviços.",
	c43:"Distribuição de lucros: A maior parte dos lucros será usada para queima de tokens HFLH, promovendo ainda mais a estabilidade e crescimento do valor dos tokens HFLH.",
	c44:" Perspectivas futuras",
	c45:"No futuro, o HFLH continuará a melhorar a qualidade e a variedade dos serviços premium para garantir que os membros possam desfrutar da melhor experiência de serviço. Ao mesmo tempo, o HFLH expandirá ativamente o mercado global, atraindo mais membros premium. Através da oferta de serviços premium e do modelo de alta lucratividade, promoverá ainda mais a queima de tokens HFLH e o crescimento do valor, alcançando o desenvolvimento sustentável a longo prazo do HFLH.",
	c46:"Diretório de executivos",
	c47:"Presidente",
	c48:"Cofundador de uma das maiores empresas de gestão de ativos do mundo, BlackRock, e uma das pessoas mais influentes do setor financeiro. Ele participa ativamente da melhoria do meio ambiente e da comunidade e atua nos conselhos de várias organizações sem fins lucrativos e universidades, incluindo a Universidade de Nova York e o Hospital Presbiteriano de Nova York.",
	c49:"Ex-executiva sênior do Google, com profunda compreensão da tecnologia blockchain. Ela lidera o planejamento estratégico e a execução do HFLH, comprometida em promover o desenvolvimento global do projeto.",
	c50:"Ex-diretor de tecnologia blockchain da IBM, um líder em inovação tecnológica. Ele tem vasta experiência no desenvolvimento e implementação de tecnologia blockchain, responsável pela arquitetura e desenvolvimento técnico do HFLH.",
	c51:"Ex-sócia sênior da KPMG, com vasta experiência em gestão financeira. Ela é responsável pelo planejamento e gestão financeira do HFLH, garantindo a saúde e transparência financeira do projeto.",
	c52:"Firma de advocacia líder mundial, fornecendo garantias legais para o HFLH.",
	c53:"A Kirkland & Ellis LLP tem vasta experiência em lidar com questões jurídicas internacionais e uma equipe jurídica forte, garantindo a legalidade e eficácia de todas as transações e acordos legais.",
	c54:"Banco de investimento de renome internacional, fornecendo consultoria financeira e serviços de investimento para o HFLH.",
	c55:"O Morgan Stanley oferece uma ampla gama de serviços financeiros globalmente, ajudando o HFLH a realizar operações de capital e expandir o mercado.",
	c56:"Fornecendo soluções de computação em nuvem e armazenamento de dados para apoiar a arquitetura técnica do HFLH.",
	c57:"O Google Cloud oferece serviços em nuvem eficientes, seguros e confiáveis para o HFLH, garantindo alta disponibilidade e escalabilidade da plataforma.",
	c58:"Fornecendo suporte de tecnologia blockchain para ajudar o HFLH a alcançar inovação e desenvolvimento tecnológico.",
	c59:"A IBM Blockchain tem vasta experiência no desenvolvimento e implementação de tecnologia blockchain, apoiando a infraestrutura blockchain do HFLH.",
	c60:"Diretório de investidores",
	c61:"Empresa de capital de risco líder mundial, fornecendo suporte de capital e orientação estratégica para o HFLH. A Sequoia Capital tem ampla experiência em investir em tecnologia e inovação, ajudando o HFLH a crescer rapidamente e expandir o mercado.",
	c62:"Famosa empresa de capital de risco, focada em investimentos em tecnologia e blockchain. Andreessen Horowitz forneceu importante suporte financeiro e orientação técnica para o HFLH, ajudando o projeto a inovar tecnologicamente e expandir o mercado.",
	c63:"Investiu em várias empresas de tecnologia de renome, fornecendo suporte de crescimento para o HFLH. A Union Square Ventures tem vasta experiência em promover o desenvolvimento de empresas inovadoras, ajudando o HFLH a se posicionar favoravelmente no mercado.",
	c64:"Empresa de capital de risco com influência global, apoiando o desenvolvimento internacional do HFLH. A Lightspeed Venture Partners, através de sua rede e recursos globais, ajuda o HFLH a expandir o mercado globalmente e a crescer nos negócios.",
	c65:"Contato",
	c66:"E-mail",
	c67:"Twitter",
	c68:"Baixar whitepaper",
	c69:"Clique para baixar o whitepaper"
}
export default pty
// src/utils下创建i18n/index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
 
// 引入各个语言配置文件
// import zh from '../local/zh.js'


import en from '../local/en.js'
import pty from '../local/pty.js'
import xby from '../local/xby.js'
import ydl from '../local/ydl.js'
import zhcn from '../local/zh-cn.js'
 
Vue.use(VueI18n)
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: 'en', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    pty,
    en,
	xby,
	ydl,
	zhcn
	
  }
})
// 暴露i18n
export default i18n
const en = {
		a1: "Project Introduction",
		aa11: "Growing with Capital",
		aa12: "Project System",
		aa13: "Project Advantages",
		aa14: "Project Security",
		aa15: "Project Sustainability",
		aa16: "Future Plans",
		aa17: "High-end Service Assurance Plan",
		aa18: "Executive Directory",
		aa19: "Partners",
		aa121: "Partner VCs Directory",
		aa122: "Contact Information",
		a2: "Project Introduction",
		a3: "Project Background & Establishment",
		a4: "Founded in 2021 and based in Singapore, the Future Horizons Foundation is a nonprofit organization focusing on blockchain technology research and applications. The foundation aims to promote the development and popularization of blockchain technology, striving to create a more transparent, secure, and efficient digital world through technological innovation and collaboration.",
		a5: "Token Introduction & Distribution",
		a6: "Token Name",
		a7: "Initial Issuance",
		a8: "1000 million",
		a9: "Issuance Rules",
		a10: "Stake 100BNB to get tokens worth 75BNB.",
		a11: "Return HFLH to get back the staked BNB",
		a12: "Retrieve staked BNB and burn all corresponding tokens.",
		a13: "Token Usage",
		a14: "100 million tokens, along with the initial BNB provided by the foundation, will enter the trading pool.",
		a15: "Project Goals",
		a16: "The goal of the Future Horizons Foundation is to promote blockchain technology's development and application through innovation and collaboration, fostering social progress and prosperity. Specific objectives include technological innovation, widespread application, talent cultivation, and social responsibility.",
		a17: "Goal of Creating Wealth",
		a18: "The core goal of the HFLH project is to become a source of wealth for all participants. This means that participants can not only obtain initial returns but also continue to receive benefits through long-term participation. By designing a stable and promising digital currency ecosystem, HFLH aims to create a sustainable and constantly appreciating wealth platform for all members.",
		a19: "Logic of Value Creation in Digital Currency",
		a20: "The essence of digital currency lies in its representation of value. When HFLH is priced at 1 BNB with a total supply of 100 million, the market cap is 100 million BNB. For members holding HFLH, this means that their holdings directly reflect their wealth. For example, a member holding 1000 HFLH can exchange them for 1000 BNB, provided there is sufficient liquidity in the market. The more members hold HFLH, the more active the market becomes, and the more stable HFLH's value is. In this context, the value brought by consensus becomes evident. HFLH can achieve stable value and market recognition through broad market participation and holding willingness. This consensus formation not only depends on the increase in the number of tokens held but also on the market's continued trust and recognition of HFLH's value.",
		a21: "Strong and Sustained Consensus",
		a22: "The key to HFLH's success lies in establishing a strong and sustained market consensus. However, relying solely on ideas cannot achieve this goal. The staking loan mechanism provides strong value support for HFLH. When the price of ordinary tokens falls, it often triggers panic selling, causing prices to plummet without a bottom or even to zero. Through staking loans, members can obtain new tokens while ensuring the safety of their principal. Specifically, when a member stakes 100 BNB, they can receive HFLH tokens worth 75 BNB. Unless the price of the tokens rises by more than a third, the member will not easily sell the tokens. This mechanism institutionally ensures the patience of new participants; even if token prices fluctuate, they will not easily sell the tokens. At the same time, when the token price drops, members can withdraw their BNB through loan repayment without having to sell HFLH in the market, avoiding a stampede.",
		a23: "Continual Increase in Market Cap and Participant Profits",
		a24: "HFLH's market cap is bound to rise continuously with market recognition and the increase of participants. The foundation distributes the growth in market cap to all participants, allowing them to share the gains brought by HFLH's growth. This profit-sharing mechanism not only attracts more participants but also enhances the confidence of existing members, further promoting market activity. By quickly creating value and distributing it to all participants, HFLH can form a virtuous circle: enhanced market consensus, increased market cap, more participants joining, thereby further stabilizing and enhancing the value of HFLH.",
		a25: "Growing with Capital",
		a26: "HFLH Participants and Market Cap Growth",
		a27: "As the number of participants in HFLH continues to grow, the market cap will also increase. This growth creates a positive cycle because more participants mean greater market demand, which drives up token prices. The growth in market cap is not only a rise in token prices but also reflects market confidence and expectations for the project's future. The stability of market cap growth is crucial for attracting large capital investments, as capital considers market stability and growth potential in its investment decisions.",
		a28: "Capital Investment and Market Stability",
		a30: "Capital investment not only brings more funds but also enhances HFLH's market recognition and influence. The more capital, the higher the requirements for market cap stability and growth rate. Large capital typically invests in projects with stable market cap and sustainable growth potential, ensuring the safety and appreciation potential of its funds. For HFLH, as long as it maintains stable market cap growth, it can continuously attract large capital's attention and investment.",
		a31: "Holding for Appreciation Strategy",
		a32: "When participating in the HFLH project, capital is not eager to realize short-term gains by selling tokens. Instead, they focus on the appreciation brought by long-term holding. The continuous appreciation of HFLH tokens means that the value of tokens held by capital also continues to rise. This not only increases the capital's own market cap but also boosts the capital's stock price by enhancing market recognition. Therefore, holding HFLH tokens and enjoying the long-term benefits of appreciation becomes the main strategy for large capital.",
		a33: "Market Cap Stability and Capital Confidence",
		a34: "The key to the success of the HFLH project lies in maintaining stable market cap growth. This stability comes not only from the increase in the number of participants but also from market confidence in HFLH's future prospects. By continuously improving project mechanisms, enhancing market transparency, and encouraging participant enthusiasm, HFLH can build a strong market trust system. As long as HFLH can prove its potential for stable and sustainable growth, capital will be confident in holding its tokens long-term, thereby achieving sustained market cap growth.",
		a35: "Diversity of Market Participants",
		a36: "As the HFLH project develops, market participants will not be limited to individual investors but will also include institutional investors and large capital. This diverse participant structure can bring more market support and capital inflow to HFLH. The participation of institutional investors and large capital not only enhances the project's market influence but also helps HFLH achieve faster market expansion and market cap growth through their professional investment strategies and resources.",
		a37: "Summary",
		a38: "Through the continuous increase in participants and market cap, the HFLH project attracts large capital investments and achieves capital's market cap growth through a holding-for-appreciation strategy. As long as HFLH maintains stable market cap growth, market participants' confidence will continue to strengthen, and capital will be more willing to hold its tokens long-term, thereby promoting the project's continuous development and increasing market cap.",
		a40: "Through this mechanism, HFLH can not only maximize its market value but also provide a stable source of income for participants and capital, forming a virtuous cycle that promotes the project's long-term sustainable development.",
		a50: "Project System",
		a51: "Staking & Lending",
		a52: "The HFLH project allows members to stake BNB and lend tokens based on the real-time price. The specific rules are as follows:",
		a53: "Members can stake 100 BNB to lend tokens worth 75 BNB (example).",
		a54: "When members return the borrowed tokens to the contract, they can fully redeem the staked 100 BNB.",
		a55: "Each address can have up to five simultaneous loans.",
		a56: "This mechanism not only provides liquidity but also ensures that members can obtain tokens by staking BNB, allowing them to participate in more project activities.",
		a57: "The initial issuance is 100 million tokens. The only way to increase the total token supply is through staking BNB and borrowing tokens; the borrowed tokens are the newly minted tokens. When members return the tokens to retrieve their BNB, the tokens are burned. For every new token minted, there will be corresponding BNB as collateral.",
		a60: "Staking Mining",
		a61: "The HFLH project encourages members to participate in mining by staking their tokens. This system will end when the total market cap reaches 20 million BNB. The parameters below may be adjusted according to market conditions. New parameters will only affect new staking, and already staked portions will not be affected. The specific rules are as follows:",
		a62: "Members can mine once daily after staking tokens.",
		a63: "The amount mined is positively correlated with the staked amount.",
		a64: "There are three staking duration options: 10 days, 30 days, and 90 days.",
		a65: "10-day staking yields a daily mining return of 0.1%, but the staked amount does not count towards effective membership and does not generate any upper-level and agency income.",
		a66: "30-day staking yields a daily mining return of 0.2%, and the staked amount can be used as a basis for effective membership calculation and generates upper-level income.",
		a67: "90-day staking yields a daily mining return of 0.3%, and the staked amount can be used as a basis for effective membership calculation and generates upper-level income.",
		a68: "This mechanism incentivizes members to hold tokens long-term, providing the project with a stable token supply and promoting the ecosystem's healthy development.",
		a69: "When staking mining, members can fill in the principal return address, so that when the principal is returned, it will be sent to the designated address.",
		a70: "Team Earnings",
		a71: "The foundation offers referral rewards to all members. The specific rules of the referral reward mechanism are as follows:",
		a72: "After a member stakes at least 0.02 BNB (based on the real-time token price at the time of staking) in a single staking mining, they can earn three levels of income by referring friends to participate in staking mining.",
		a73: "First level 10%",
		a74: "Second level 5%",
		a75: "Third level 3%",
		a76: "When referred members within the three levels stake mine, the referrer can earn direct token income.",
		a77: "This mechanism encourages members to actively promote the project, increasing participation and the user base.",
		a78: "Agency Earnings",
		a79: "The foundation offers generous rewards for agents. This system stops when the number of members reaches 30 million. The specific rules are as follows:",
		a80: "Agency levels are divided into seven levels, with new members starting at level one.",
		a81: "Effective Member: A single staking mining of at least 0.2 BNB, based on the real-time token price at the time of staking. Multiple stakes can be made, and as long as one of them reaches 0.2 BNB, the member is considered an effective member.",
		a82: "Agency upgrades occur when the number of effective members within three levels reaches the specified number, up to level six. For example, when the number of effective members within three levels reaches 100, the agent level is two. When the number of effective members within three levels reaches 300, the agent level is three.",
		a83: "Agency Earnings:",
		a84: "Level one (fewer than 100 effective members within three levels): Earn tokens worth 0.02 BNB for each additional effective member.",
		a85: "Level two (fewer than 300 effective members within three levels): Earn tokens worth 0.024 BNB for each additional effective member (0.004 BNB more than level one).",
		a86: "Level three (fewer than 500 effective members within three levels): Earn tokens worth 0.028 BNB for each additional effective member (0.004 BNB more than level two).",
		a87: "Level four (fewer than 700 effective members within three levels): Earn tokens worth 0.032 BNB for each additional effective member (0.004 BNB more than level three).",
		a88: "Level five (fewer than 1000 effective members within three levels): Earn tokens worth 0.036 BNB for each additional effective member (0.004 BNB more than level four).",
		a89: "Level six (fewer than 1500 effective members within three levels): Earn tokens worth 0.04 BNB for each additional effective member (0.004 BNB more than level five).",
		a90: "Earnings Distribution:",
		a91: "Earn half of the earnings immediately for each additional effective member. For example, when a level one agent adds a new effective member, they immediately receive tokens worth 0.01 BNB.",
		a92: "Upon reaching the first hundred members, all remaining earnings of that level are given to the agent. For example, when a level one agent adds the hundredth effective member, they receive tokens worth 1.01 BNB (i.e., 0.01 BNB + 100 * 0.01 BNB), and the agent level is upgraded to level two.",
		a93: "After reaching level six with 1500 effective members, all agency earnings are completed, and no earnings are given for the 1501st member.",
		a94: "Key Point",
		a95: "After reaching 1500 effective members, the agent becomes a shareholder. For each additional effective member across the network, the system will issue tokens worth 0.02 BNB, accumulated as shareholder earnings.",
		a96: "After reaching 30 million effective members, all shareholders will share the accumulated shareholder earnings in one go.",
		a97: "Slippage Settings",
		a98: "buy ",
		a99: "Sell ",
		a100: "Withdrawal ",
		b1: "Project Advantages",
		b2: "Risk-free Returns",
		b3: "The HFLH project allows participants to obtain tokens through staking loans, without any risk of loss, whether holding or staking mining. The specific advantages are as follows:",
		b4: "Principal Security:",
		b5: "If the token price drops, simply return the borrowed tokens to the contract, ensuring principal security.",
		b6: "Risk-free Returns:",
		b7: "Participate in staking mining, where mining returns are risk-free, even if the token price drops.",
		b8: "benefit from Price Drops",
		b9: "The HFLH project innovatively allows users to benefit even when the token price drops. The specific advantages are as follows:",
		b10: "Short Selling Mechanism:",
		b11: "Users can sell the borrowed tokens and buy them back with part of the funds when the price drops, then return them to the contract to retrieve their principal.",
		b12: "Double-sided Profits:",
		b13: "Profit not only when prices rise but also when they fall. This is a major innovation in DeFi projects, differentiating it from traditional projects where one can only profit from rising prices.",
		b14: "Distributed Architecture",
		b15: "The HFLH project adopts a distributed network architecture, with all data and operations spread across multiple global nodes. The specific advantages are as follows:",
		b16: "No Single Point of Failure:",
		b17: "Even if some nodes encounter issues, the system can still operate normally.",
		b18: "High Availability:",
		b19: "Failure of any single node does not affect the entire project's operation, ensuring its sustainability and stability.",
		b20: "Open Source & Transparency",
		b21: "The HFLH project is open-source, allowing anyone to review, copy, and use its code. The specific advantages are as follows:",
		b22: "Transparency:",
		b23: "All rules and operations are open and transparent, enhancing user trust in the project.",
		b24: "Community Maintenance:",
		b25: "Even if the original team abandons the project, community members can continue to maintain and develop it, ensuring its sustainability.",
		b26: "Smart Contract Constraints",
		b27: "The HFLH project's operating rules are clearly defined in smart contracts, and all participants, including the project party, can only operate within these rules. The specific advantages are as follows:",
		b28: "Predetermined Rules:",
		b29: "Any operation beyond the stipulated rules will be automatically rejected by the system, ensuring fairness and transparency.",
		b30: "Rule Constraints:",
		b31: "The smart contract itself is a set of rules, and any operation must meet the contract's predetermined conditions, preventing violations of preset rules.",
		b32: "Project Security",
		b33: "Transparent Smart Contracts",
		b34: "The HFLH project relies on blockchain-based smart contracts, which are fully open and transparent. Anyone can review and audit the contract code to ensure it executes according to the predefined rules. This transparency significantly reduces the possibility of malicious behavior and fraud, enhancing user trust in the project.",
		b35: "Automated Execution",
		b36: "The HFLH project's smart contracts automatically execute when specific conditions are met, without human intervention. This automation not only increases operational efficiency but also reduces the risk of human error and operational mistakes, ensuring accuracy and consistency in contract execution.",
		b37: "High Security",
		b38: "The HFLH project is based on blockchain technology, utilizing its distributed ledger and consensus mechanism to ensure data security and immutability. The decentralized nature of blockchain makes it almost impossible to tamper with transaction records, ensuring the project's security and reliability.",
		b39: "Project Sustainability",
		b40: "Distributed Architecture",
		b41: "The HFLH project adopts a distributed network architecture, with all data and operations spread across multiple global nodes. This means that even if some nodes experience issues, the entire system can still function normally, without single-point failures or sudden shutdowns.",
		b42: "Complete Control Abandonment",
		b43: "After the HFLH project is launched, the project party will completely relinquish control. Once the smart contract is deployed on the blockchain, it cannot be modified or deleted, ensuring that the project party cannot unilaterally close or change the project's operating rules.",
		b44: "Immutable Contracts",
		b45: "Once the HFLH project's smart contracts are deployed on the blockchain, they cannot be altered. This means that the project party cannot arbitrarily close or change the project's operating rules, ensuring transparency and fairness.",
		b46: "Open Source Code",
		b47: "The HFLH project's code is open-source, allowing anyone to review, copy, and use it. This further limits the project's control capabilities, ensuring that even if the original team abandons the project, community members can continue to maintain and develop it, ensuring its sustainability.",
		b48: "No Single Point of Failure",
		b49: "Due to the HFLH project's reliance on a distributed network, there is no risk of a single point of failure. Even if a node fails, other nodes can continue to operate and maintain network stability, ensuring the project does not suddenly shut down.",
		b50: "Future Plans",
		b51: "Property Exchange Plan",
		b52: "The HFLH project plans to build an advanced property exchange through cooperation with Kirkland & Ellis LLP, one of the world's largest law firms, relying on its comprehensive legal agreements. This exchange will enable the mapping of real-world assets to digital assets, making the trading of various assets more efficient and secure.",
		b53: "Partnerships and Legal Assurance",
		b54: "Kirkland & Ellis LLP is a top global law firm with extensive experience in handling international legal matters and a strong legal team. Through its cooperation with Kirkland & Ellis LLP, HFLH will ensure the legality and validity of all transactions and legal agreements. This partnership will provide solid legal assurance for the operation of the HFLH property exchange, allowing it to legally and securely conduct various asset transactions globally.",
		b55: "Asset Mapping & Trading",
		b56: "In the property exchange, real assets will be digitally mapped to digital assets. These digital assets include but are not limited to real estate, automobiles, minerals, gold, farms, and companies. Using blockchain technology, the ownership and transaction records of these assets will be securely recorded and stored, ensuring the transparency and immutability of assets.",
		b57: "Digital Asset Mapping Process",
		b58: "Asset Assessment & Certification",
		b59: "First, a professional assessment and certification of real assets are conducted to ensure their value's accuracy and legality.",
		b60: "Digital Mapping",
		b61: "The assessed assets are mapped to corresponding digital assets, and ownership information is recorded on the blockchain.",
		b62: "Release & Trading",
		b63: "Digital assets are listed on the property exchange for users to trade.",
		b64: "This digital asset mapping method not only improves asset transaction efficiency but also increases transparency and security.",
		b65: "Fast Transactions & Privacy Protection",
		b66: "Another important feature of the property exchange is the ability to conduct quick transactions of various assets. Users can buy, sell, transfer, and mortgage assets anytime, anywhere. Compared with traditional offshore registration and hidden ownership methods, the property exchange provides a more discreet way of holding assets.",
		b67: "Fast Transaction Process",
		b68: "Asset Listing",
		b69: "Users can list their assets on the exchange for sale or rental.",
		b70: "Real-time Trading",
		b71: "Through an advanced matching system, users can conduct real-time asset trading, ensuring efficient and quick completion of transactions.",
		b72: "Fund Settlement",
		b73: "Upon transaction completion, funds are automatically settled into users' accounts, ensuring fund security and timely arrival.",
		b74: "Privacy Protection Mechanism",
		b75: "Data Encryption",
		b76: "All transaction data and user information will be encrypted to prevent data breaches and unauthorized access.",
		b77: "Anonymous Transactions",
		b78: "Support for anonymous transactions allows users to choose not to disclose their identity information, enhancing transaction privacy.",
		b79: "Multi-layer Security",
		b80: "Multi-layer security mechanisms are implemented to protect user assets and information.",
		b81: "Advantages of the Property Exchange",
		b82: "Global Trading Platform",
		b83: "The property exchange will be a global trading platform, attracting users and capital worldwide. Through advanced technology and comprehensive legal assurance, the property exchange will provide users with a safe, convenient, and efficient asset trading environment.",
		b84: "Diverse Asset Trading",
		b85: "Whether real estate, automobiles, minerals, gold, farms, or companies, the property exchange can facilitate diverse asset trading. Users can freely buy and sell various types of assets on the platform, significantly enriching the range of asset transactions.",
		b86: "Efficient Use of Funds",
		b87: "Through the property exchange, users can efficiently utilize their assets. They can not only earn returns from selling and renting assets but also obtain more financial support through asset pledges and financing. This provides users with more liquidity and improves asset utilization efficiency.",
		b88: "Future Prospects",
		b89: "As the HFLH property exchange continues to develop and improve, it will become a leading global asset trading platform. Through the continuous introduction of advanced technology and comprehensive legal assurance, the property exchange will enhance user experience and trading efficiency, attracting more users and capital.",
		b90: "Technological Innovation & Development",
		b91: "In the future, HFLH will continue to explore and introduce more innovative technologies, such as artificial intelligence and big data analysis, to improve the intelligence and automation of transactions. These technologies will help users better manage assets and make trading decisions, enhancing overall trading efficiency.",
		b92: "Market Expansion & Cooperation",
		b93: "HFLH will actively expand into global markets and seek more international cooperation opportunities. By partnering with renowned global financial institutions, legal firms, and technology companies, HFLH will further enhance its international influence and market competitiveness.",
		b94: "Conclusion",
		b95: "The establishment of the HFLH property exchange will provide users with a safe, efficient, and discreet asset trading platform. Through collaboration with Kirkland & Ellis LLP, supported by comprehensive legal agreements and advanced technology, HFLH will enable the mapping of real assets to digital assets, promoting the digitalization of global asset transactions. As the platform continues to develop and improve, HFLH will become a leader in the global asset trading field, offering better services and more investment opportunities to users and capital.",
		b96: "High-end Service Assurance Plan",
		b97: "Overview of High-end Custom Services",
		b98: "The foundation is committed to providing comprehensive and high-end custom services to members. These services not only cover all aspects of daily life but also include high-end needs, ensuring members enjoy the highest quality of life. By offering these high-end services, HFLH can create more added value for members and generate high-profit service models. The profits generated here will largely be used for HFLH token burning, promoting the stability and growth of HFLH token value.",
		b99: "Safe High-end Food",
		b100: "In terms of diet, HFLH will provide members with safe, healthy, and high-end food services. Specifically, this includes:",

		c1: "Organic Food: Members can enjoy organic food from top farms worldwide, ensuring the natural and healthy quality of ingredients.",
		c2: "Custom Nutrition Meals: Provide personalized nutrition menus based on members' health conditions and dietary preferences, ensuring balanced daily nutrition intake.",
		c3: "International Cuisine: Offer top cuisines from around the world, catering to members' diverse taste preferences.",
		c4: "Private Chef: Provide private chef services, with chefs cooking on-site to ensure the freshness and quality of food.",
		c5: "Property Purchase & Management",
		c6: "HFLH will offer comprehensive property purchase and management services, ensuring the security and appreciation of members' assets. Specific services include:",
		c7: "Global Real Estate: Members can purchase high-end properties worldwide, including luxury homes, apartments, and resorts.",
		c8: "Professional Appraisal: Provide professional real estate appraisal services, ensuring accurate property valuation and helping members make the best investment decisions.",
		c9: "Home Loan: Provide home loan support, helping members achieve their dream property purchases.",
		c10: "Property Management: Offer comprehensive property management services, including property maintenance, rental management, and asset appreciation strategies.",
		cc11: "Transportation Configuration & Maintenance",
		cc12: "To meet members' high-end transportation needs, HFLH will provide comprehensive transportation configuration and maintenance services. Specifically, this includes:",
		cc13: "Luxury Car Configuration: Provide luxury car purchase and customization services, including top brands worldwide.",
		cc14: "Yacht Services: Offer yacht purchase, customization, berthing, and maintenance services, ensuring members enjoy the ultimate luxury experience at sea.",
		cc15: "Private Jets: Provide private jet purchase, leasing, and maintenance services, ensuring members' safe and comfortable air travel.",
		c14: "Professional Maintenance: Offer regular maintenance and repair services for luxury cars, yachts, and private jets, ensuring all vehicles are always in optimal condition.",
		c15: "Premium Concierge Service",
		c16: "HFLH will provide premium concierge services, ensuring members' daily lives are orderly. Specific services include:",
		c17: "Private Butler: Provide private butler services, with butlers managing members' daily affairs, including schedule arrangements, event planning, and life management.",
		c18: "Housekeeping: Offer comprehensive housekeeping services, including cleaning, laundry, and home maintenance.",
		c19: "Personal Assistant: Provide personal assistant services, assisting members in handling daily affairs and emergencies, ensuring a worry-free life.",
		c20: "Security Assurance",
		c21: "HFLH places high importance on member security, offering comprehensive security assurance services to ensure members' personal and property safety. Specifically, this includes:",
		c22: "Private Bodyguards: Provide professional private bodyguard services, with bodyguards possessing high-level security skills and rich experience.",
		c23: "Security Monitoring: Offer advanced security monitoring systems to ensure the safety of members' living environments.",
		c24: "Emergency Rescue: Provide 24-hour emergency rescue services, including medical first aid and emergency handling.",
		c25: "Risk Assessment: Offer professional security risk assessment services, helping members identify and mitigate potential security risks.",
		c26: "Financial Consulting",
		c27: "HFLH will provide professional financial consulting services to help members with scientific financial planning and asset management. Specifically, this includes:",
		c28: "Investment Advisor: Provide professional investment advisory services, helping members formulate personalized investment strategies and achieve stable asset appreciation.",
		c29: "Tax Consulting: Offer tax consulting services, helping members plan taxes reasonably and minimize tax burdens.",
		c30: "Financial Planning: Provide comprehensive financial planning services, including retirement planning, estate planning, and children's education funds.",
		c31: "Asset Management: Provide professional asset management services, helping members optimize asset allocation and appreciation.",
		c32: "Children's Education",
		c33: "HFLH places high importance on members' children's education, offering comprehensive child education support services to ensure their overall development. Specifically, this includes: ",
		c34: "Top Schools: Help members' children enter top schools worldwide, including international schools and prestigious universities.",
		c35: "Education Advisor: Provide professional education advisory services, helping members plan their children's education.",
		c36: "Academic Tutoring: Offer comprehensive academic tutoring services to help members' children achieve excellent results in various subjects.",
		c37: "Extracurricular Activities: Organize a wide range of extracurricular activities and interest classes, helping members' children develop comprehensively.",
		c38: "High-quality, High-profit Service Model",
		c39: "HFLH's high-end services will focus on high quality and high profits, ensuring top-quality services and high added value. Specific measures include:",
		c40: "Strict Selection: Strictly select all service providers to ensure the quality and safety of services.",
		c41: "Customized Services: Provide customized services based on members' personalized needs, ensuring precision and care.",
		c42: "High Added Value: Achieve high profits by providing high-value-added services, ensuring the continuity and stability of services.",
		c43: "Profit Distribution: Most of the profits obtained will be used for burning HFLH tokens, further promoting the stability and growth of HFLH token value.",
		c44: "Future Prospects",
		c45: "In the future, HFLH will continue to improve the quality and variety of high-end services, ensuring members enjoy the highest quality service experience. At the same time, HFLH will actively expand into global markets, attracting more high-end members. By providing high-end services and a high-profit model, HFLH will further promote HFLH token burning and value growth, achieving long-term sustainable development for HFLH.",
		c46: "Executive Directory",
		c47: "Chairman",
		c48: "Co-founder of BlackRock, one of the world's largest asset management companies, and one of the most influential figures in the financial world. Actively involved in improving the environment and communities, he serves on the boards of various non-profit organizations and universities, including NYU and New York Presbyterian Hospital.",
		c49: "Former senior management at Google with a deep understanding of blockchain technology. She leads HFLH's strategic planning and execution, dedicated to promoting the project's global development.",
		c50: "Former IBM Blockchain Technology Director and leader in technological innovation. He has extensive experience in blockchain technology development and implementation, responsible for HFLH's technical architecture and development.",
		c51: "Former senior partner at KPMG with rich financial management experience. She is responsible for HFLH's financial planning and management, ensuring the project's financial health and transparency.",
		c52: "Provides legal assurance for HFLH.",
		c53: "Kirkland & Ellis LLP has extensive experience in handling international legal matters and a strong legal team, ensuring the legality and validity of all transactions and legal agreements.",
		c54: "Internationally renowned investment bank, providing financial consulting and investment services for HFLH.",
		c55: "Morgan Stanley offers a wide range of financial services globally, helping HFLH with capital operations and market expansion.",
		c56: "Provides cloud computing and data storage solutions, supporting HFLH's technical architecture.",
		c57: "Google Cloud provides efficient, secure, and reliable cloud services for HFLH, ensuring the platform's high availability and scalability.",
		c58: "Provides blockchain technology support, helping HFLH achieve technological innovation and development.",
		c59: "IBM Blockchain has extensive experience in blockchain technology development and implementation, supporting HFLH's blockchain infrastructure construction.",
		c60: "Partner VCs Directory",
		c61: "Top global VC firm providing capital support and strategic guidance to HFLH. Sequoia Capital has extensive investment experience in technology and innovation, helping HFLH achieve rapid growth and market expansion.",
		c62: "Well-known VC firm focusing on technology and blockchain investments. Andreessen Horowitz provides important funding and technical guidance to HFLH, supporting the project's technological innovation and market development.",
		c63: "Invested in numerous well-known tech companies, providing growth support to HFLH. Union Square Ventures has rich experience in promoting innovative enterprises, helping HFLH gain a favorable position in market competition.",
		c64: "Globally influential VC firm supporting HFLH's international development. Lightspeed Venture Partners helps HFLH achieve global market expansion and business growth through its global network and resources.",
		c65: "Contact Information",
		c66: "Email",
		c67: "Twitter",
		c68: "Whitepaper Download",
		c69: "Click to download the whitepaper"

	}
	
	export default en